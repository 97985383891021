import { useParams } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTracking } from '@/hooks/useTracking';
import { useGetUserEntities } from '@/api/userEntities/userEntities.hooks';
import { MonitoredEntitiesTable } from '@/views/MonitoringDashboardView/components/MonitoredEntitiesTable/MonitoredEntitiesTable';
import Loader from '@/components/atoms/Loader/Loader';
import {
  StyledContainer,
  StyledLoaderContainer,
  StyledButton,
  StyledLink,
} from '@/views/MonitoringDashboardView/MonitoringDashboardView.styled';
import { ERoutes } from '@/types/routes';

export const MonitoringDashboardView = () => {
  const { dashboardType } = useParams();
  const isCustomDashboard = dashboardType === 'custom';

  const payload = isCustomDashboard
    ? { entityType: null }
    : {
        entityType: dashboardType || '',
      };

  const { fetchedUserEntities, isLoading, error } = useGetUserEntities(payload);
  const { trackEvent } = useTracking();

  if (isLoading) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  if (error || !fetchedUserEntities) {
    throw error;
  }

  return (
    <StyledContainer>
      <MonitoredEntitiesTable monitoredEntities={fetchedUserEntities} />
      {isCustomDashboard && (
        <StyledLink
          href={ERoutes.MONITORING_SETTINGS}
          underline="none"
          onClick={() => trackEvent('Monitoring - click settings', {})}
        >
          <StyledButton variant="outlined" color="tertiary" skipTypography fitContentWidth>
            <AddCircleOutlineIcon />
            Add entity
          </StyledButton>
        </StyledLink>
      )}
    </StyledContainer>
  );
};
