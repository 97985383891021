import { UseFormReturn } from 'react-hook-form';
import { formatFloatToPercent } from '@/helpers/helpers';
import { ControlledBaseTextField, EInputValueFormatter } from '@/components/atoms/BaseTextField/BaseTextField';
import { ISingleRowProps } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/SingleRow';
import { StyledMuiTextInput } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/Input.styled';
import { IRiskSimulatorForm, IRow } from '@/views/RiskSimulatorPortfolioView/utils/types';

interface IPdInputProps extends Pick<ISingleRowProps, 'idx' | 'isCustom'> {
  formMethods: UseFormReturn<IRiskSimulatorForm>;
  formRowsValues: IRow[];
}

export const PdInput = ({ formMethods, idx, isCustom, formRowsValues }: IPdInputProps) => {
  if (isCustom) {
    return (
      <ControlledBaseTextField
        valueFormatter={EInputValueFormatter.PERCENTAGE}
        formMethods={formMethods}
        inputName={`rows.${idx}.pd`}
        helperTextBackgroundColor="#26284b"
        placeholder="XX.XX%"
        CustomTextInput={StyledMuiTextInput}
      />
    );
  }

  const value = formRowsValues[idx].pd === 0 ? 'XX.XX%' : formatFloatToPercent(formRowsValues[idx].pd);

  return <StyledMuiTextInput value={value} disabled />;
};
