import { Navigate } from 'react-router-dom';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { ERoutes } from '@/types/routes';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps): React.ReactElement | null => {
  const { isAuthenticated, user } = useNewAuth();
  const { shouldDisplayAdminPanel } = usePermissionsManager();

  if (!user || !isAuthenticated) {
    return <Navigate to={ERoutes.BASE} />;
  }

  if (!shouldDisplayAdminPanel) {
    return <Navigate to={ERoutes.MONITORING} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
