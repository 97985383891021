import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)`
  border-color: transparent;
  flex: none;
  height: auto;
  font-size: 14px;

  .MuiDataGrid-sortIcon,
  .MuiDataGrid-filterIcon,
  .MuiDataGrid-menuIconButton {
    color: #fff;
  }

  .MuiDataGrid-columnHeader:focus-within {
    outline: none;
    border: none;
  }

  .MuiDataGrid-columnHeaderTitle {
    opacity: 0.5;
  }

  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-footerContainer {
    border-bottom: 1px solid #2e2e31;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-row.Mui-selected,
  .MuiDataGrid-row.Mui-selected.Mui-hovered,
  .MuiDataGrid-row:hover {
    background: #222;
    cursor: pointer;

    .highcharts-background {
      fill: #222;
    }
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
