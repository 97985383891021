import { StyledArrowIcon, StyledText } from './RiskIndicator.styled';
import { TRiskIndicator } from '@/types/severityLevel';

interface IRiskIndicatorProps {
  riskIndicator: TRiskIndicator;
}

export const RiskIndicator = ({ riskIndicator }: IRiskIndicatorProps) => {
  const isPending = riskIndicator === 'pending';

  return (
    <StyledText trend={riskIndicator}>
      {riskIndicator.toUpperCase()}
      {!isPending && <StyledArrowIcon trend={riskIndicator} />}
    </StyledText>
  );
};
