export function assertIsDefined<T>(arg: T | null | undefined, errorMessage: string): asserts arg is T {
  if (arg !== undefined && arg !== null) return;

  throw new Error(errorMessage);
}

export function assertIsDefinedAndNullable<T>(arg: T | null | undefined, errorMessage: string): asserts arg is T {
  if (arg !== undefined) return;

  throw new Error(errorMessage);
}
