import AgioLogo from '@/assets/icons/sidebar-logo.svg';
import {
  StyledCloseButton,
  StyledContentWrapper,
  StyledInfo,
  StyledLogo,
  StyledTitle,
  StyledWrapper,
} from './NewSeverityLevelsModalContent.styled';
import { IModalAsSingleViewedBannerContentProps } from '@/types/modal';

export const NewSeverityLevelsModalContent = ({
  closeModal,
  localStorageKey,
}: IModalAsSingleViewedBannerContentProps) => {
  const handleClick = () => {
    localStorage.setItem(localStorageKey, 'viewed');
    closeModal();
  };

  return (
    <StyledWrapper>
      <StyledLogo src={AgioLogo} alt="Agio logo" />
      <StyledContentWrapper>
        <StyledTitle color="white.100">
          Update to <br />
          Alert Severity Levels
        </StyledTitle>
        <StyledInfo>
          We’re excited to announce an improvement to our alert system, designed to offer you a better product
          experience. We understand that using z-scores can be challenging to interpret, so we’ve transitioned to a more
          intuitive severity categorization: None, Low, Medium, High, and Extreme. This new framework helps you quickly
          assess the importance of each alert.
        </StyledInfo>
        <StyledInfo>
          For those interested in the technical details, a more comprehensive explanation can be found in our
          documentation.
        </StyledInfo>
        <StyledInfo>Thank you for your continued trust in our platform.</StyledInfo>
        <StyledCloseButton onClick={handleClick}>Got it!</StyledCloseButton>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};
