import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { anomaliesEmailNotificationsOptions } from '@/views/MonitoringSettingsView/utils/constants';
import { StyledRadio } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/components/NotificationsModeRadioGroup/NotificationsModeRadioGroup.styled';
import { IEntitiesEmailNotificationsFormProps } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/EntitiesEmailNotificationsForm';

export const NotificationsModeRadioGroup = ({
  formMethods,
}: Pick<IEntitiesEmailNotificationsFormProps, 'formMethods'>) => {
  return (
    <FormControl>
      <Controller
        render={({ field }) => {
          return (
            <RadioGroup
              row
              aria-labelledby="email-notifications-radio-group"
              {...field}
              name="email-notifications-radio-group"
            >
              {anomaliesEmailNotificationsOptions.map((option) => {
                return (
                  <FormControlLabel
                    key={option.label}
                    value={option.value}
                    checked={option.value === field.value}
                    control={<StyledRadio />}
                    label={
                      <Typography color="white.100" fontSize="13px">
                        {option.label}
                      </Typography>
                    }
                  />
                );
              })}
            </RadioGroup>
          );
        }}
        name="notificationsMode"
        control={formMethods.control}
      />
    </FormControl>
  );
};
