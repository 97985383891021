import { useEffect, useMemo, useState } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { IAutocompleteManager, IRiskSimulatorForm, IRow } from '@/views/RiskSimulatorPortfolioView/utils/types';
import { IOption } from '@/types/select';

interface IUseAutocompleteOptionsManagerProps {
  watchedRowsValues: IRow[];
  exchangeList: string[];
  rowFields: FieldArrayWithId<IRiskSimulatorForm, 'rows', 'id'>[];
}

export const useAutocompleteOptionsManager = ({
  watchedRowsValues,
  exchangeList,
  rowFields,
}: IUseAutocompleteOptionsManagerProps) => {
  const isMultipleEntities = useMemo(() => rowFields.length > 1, [rowFields]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const selectInitialOptions: IOption[] = useMemo(() => {
    return exchangeList.map((e) => ({ label: getFullExchangeName(e), value: e }));
  }, [exchangeList]);

  const handleSelectedOptionChange = (value: unknown, idx: number) => {
    if (typeof value === 'string') {
      setSelectedOptions((prev) => {
        prev[idx] = value;
        return prev;
      });
    }
  };

  const removeDeletedOptionFromSelections = (idx: number) => {
    setSelectedOptions((prev) => {
      return [...prev].filter((option, index) => {
        return index !== idx;
      });
    });
  };

  const clearSelectedOptions = () => setSelectedOptions([]);

  const filteredOptions = [...selectInitialOptions].filter((option) => !selectedOptions.includes(option.value));

  useEffect(() => {
    if (!selectedOptions.length && isMultipleEntities) {
      const filterFilledInAutocompleteInputs = watchedRowsValues.filter((row) => !row.isCustom && row.entity);
      if (filterFilledInAutocompleteInputs.length > 0) {
        const listOfEntitiesToSelect = filterFilledInAutocompleteInputs.map((row) => row.entity);
        setSelectedOptions(listOfEntitiesToSelect);
      }
    }
  }, [isMultipleEntities, watchedRowsValues, selectedOptions]);

  const autocompleteManager: IAutocompleteManager = {
    filteredOptions,
    handleSelectedOptionChange,
    removeDeletedOptionFromSelections,
    isMultipleEntities,
    clearSelectedOptions,
  };

  return {
    autocompleteManager,
  };
};
