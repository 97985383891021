import { ChainKey } from '@/types/chain';

export const adjustTrxHashHref = (chainKey: ChainKey, trxHash: string) => {
  switch (chainKey) {
    case 'arbitrum_one':
      return `https://arbiscan.io/tx/${trxHash}`;
    // case 'avalanche':
    //   return '';
    // case 'base':
    //   return '';
    case 'bitcoin':
      return `https://mempool.space/${trxHash}`;
    case 'bsc':
      return `https://bscscan.com/tx/${trxHash}`;
    case 'ethereum':
      return `https://etherscan.io/tx/${trxHash}`;
    // case 'optimism':
    //   return '';
    // case 'polygon':
    //   return '';
    case 'solana':
      return `https://solscan.io/tx/${trxHash}`;
    case 'tron':
      return `https://tronscan.org/#/transaction/${trxHash}`;
    default:
      return `https://etherscan.io/tx/${trxHash}`;
  }
};

export const adjustAddressHref = (chainKey: ChainKey, address: string) => {
  switch (chainKey) {
    case 'arbitrum_one':
      return `https://arbiscan.io/address/${address}`;
    // case 'avalanche':
    //   return '';
    // case 'base':
    //   return '';
    case 'bitcoin':
      return `https://blockstream.info/address/${address}`;
    case 'bsc':
      return `https://bscscan.com/address/${address}`;
    case 'ethereum':
      return `https://etherscan.io/address/${address}`;
    // case 'optimism':
    //   return '';
    // case 'polygon':
    //   return '';
    case 'solana':
      return `https://solscan.io/account/${address}`;
    case 'tron':
      return ` https://tronscan.org/#/address/${address}`;
    default:
      return `https://etherscan.io/address/${address}`;
  }
};
