import React from 'react';
import { Typography } from '@mui/material';
import { Draggable, DroppableProvided, DroppableStateSnapshot } from '@hello-pangea/dnd';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { StyledDragItem, StyledDragList } from './DraggableEntitiesList.styled';

interface ISortedEntity {
  item: string;
  imageUrl: string;
}

interface IDraggableEntitiesListProps {
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
  sortedEntities: ISortedEntity[];
}

export const DraggableEntitiesList = ({ provided, snapshot, sortedEntities }: IDraggableEntitiesListProps) => {
  return (
    <StyledDragList {...provided.droppableProps} ref={provided.innerRef}>
      {sortedEntities.map(({ item, imageUrl }, index) => (
        <Draggable key={item} draggableId={item} index={index}>
          {(provided, snapshot) => (
            <StyledDragItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
            >
              <ExchangeLogo companyName={imageUrl} />
              <Typography color="white.100" noWrap>
                {item}
              </Typography>
            </StyledDragItem>
          )}
        </Draggable>
      ))}
      {provided.placeholder}
    </StyledDragList>
  );
};
