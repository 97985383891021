import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import { AccordionDetails, AccordionSummary, Paper } from '@mui/material';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  padding: 24px;
  border: 1px solid #2e2e31;
  width: 100%;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  margin: 0 auto;
  border-radius: 4px;
  background-color: #101014;
  width: 70%;

  //Input border basic + on hover
  & fieldset,
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.54);
  }

  //Button white text
  .MuiButtonBase-root {
    color: #fff;

    //Button close icon color
    svg {
      fill: rgba(255, 255, 255, 0.54);
    }
  }

  //Input white text
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
  }

  //Main label color behavior
  label {
    color: rgba(255, 255, 255, 0.54);
    transition: color 0.3s;

    &.Mui-focused {
      color: #5d51b8;
    }

    &.Mui-error {
      color: #d32f2f;
    }
  }
`;

export const StyledPaper = styled(Paper)`
  background: #1b1b1e;
  border-radius: 4px;
  color: #fff;

  .MuiAutocomplete-noOptions {
    color: #fff;
  }
`;

export const GroupHeader = styled.div`
  position: sticky;
  top: -8px;
  z-index: 1;
  padding: 4px 10px;
  background-color: #5d51b8;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.23px;
  color: #fff;
  text-transform: uppercase;
`;

export const GroupItems = styled.ul`
  padding: 0;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #222;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.23px;
  color: #fff;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background-color: #222222;
`;
