import { Stack } from '@mui/material';
import AddIcon from '@/assets/icons/add-circle.svg';
import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { SectionTitle } from '@/views/RiskSimulatorPortfolioView/components/SectionTitle/SectionTitle';
import { SimulationRows } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SimulationRows/SimulationRows';
import {
  StyledWrapper,
  StyledDatePicker,
  StyledButton,
  StyledFormHelperText,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/CounterpartyExposures.styled';

export const CounterpartyExposures = () => {
  const { datePickerManager, rowsManager } = useRiskSimulator();
  const { availableDatesRange, selectedDate, handleDatePickerChange } = datePickerManager;
  const { handleAddRow, handleAddCustomRow, entityError } = rowsManager;

  return (
    <StyledWrapper>
      <SectionTitle
        title="Counterparty Exposures"
        tooltipDescription={
          <>
            <p>
              The exposure, expressed in USD, is the total amount at risk for each counterparty whilst the recovery
              percentage is the expected proportion of the exposure that can be recovered in the event of a default. By
              default, the recovery percentage is set to zero but can be modified by the user.
            </p>
            <p>
              For Agio rated counterparties, the 1-YR PDs are taken from the Statistical Ratings however users can input
              custom counterparties and modify their 1-YR PD.
            </p>
          </>
        }
      />

      <StyledDatePicker
        name="select-date"
        format="YYYY-MM-DD"
        minDate={availableDatesRange.start}
        maxDate={availableDatesRange.end}
        value={selectedDate}
        onChange={handleDatePickerChange}
      />

      <SimulationRows />

      {!!entityError && <StyledFormHelperText>{entityError.message}</StyledFormHelperText>}

      <Stack direction="row" gap="20px">
        <StyledButton variant="text" color="tertiary" fitContentWidth skipTypography onClick={handleAddRow}>
          <img src={AddIcon} alt="Add icon" />
          Exchange
        </StyledButton>
        <StyledButton variant="text" color="tertiary" fitContentWidth skipTypography onClick={handleAddCustomRow}>
          <img src={AddIcon} alt="Add icon" />
          Custom
        </StyledButton>
      </Stack>
    </StyledWrapper>
  );
};
