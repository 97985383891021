import { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import DownloadIcon from '@/assets/icons/download.svg?react';
import { downloadFile } from '@/helpers/file';
import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { useFullReportPdfManager } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/utils/useFullReportPdfManager';
import { generateRiskMeasuresData } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/utils/helpers';
import { SectionTitle } from '@/views/RiskSimulatorPortfolioView/components/SectionTitle/SectionTitle';
import {
  StyledButton,
  StyledContainedButton,
  StyledHeaderWrapper,
  StyledMeasureResult,
  StyledMeasureRow,
  StyledMeasuresWrapper,
  StyledMeasureText,
  StyledWrapper,
} from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/RiskMeasures.styled';
import { RowTitles } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/utils/types';

export const RiskMeasures = () => {
  const { resultOfRiskSimulation, parametersOfDisplayedSimulation } = useRiskSimulator();

  const {
    totalExposure,
    expectedLossUsd,
    expectedLossPct,
    unexpectedLossUsd,
    unexpectedLossPct,
    creditVar950Pct,
    creditVar990Pct,
    creditVar999Pct,
    creditVar950Usd,
    creditVar990Usd,
    creditVar999Usd,
  } = resultOfRiskSimulation;

  const { generatePDF } = useFullReportPdfManager({ resultOfRiskSimulation, parametersOfDisplayedSimulation });

  const exportToCsv = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      // Headers for each column
      const headers = ['', 'usd_value', 'pct_value'].join(';');

      const rows = generateRiskMeasuresData(resultOfRiskSimulation);

      const result = [headers, ...rows].join('\n');

      downloadFile({
        data: result,
        fileName: 'risk_measures.csv',
        fileType: 'text/csv',
      });
    },
    [resultOfRiskSimulation],
  );

  return (
    <StyledWrapper>
      <StyledHeaderWrapper>
        <SectionTitle
          title="Risk Measures"
          tooltipDescription="Table of credit risk measures associated with the selected counterparty exposures. For a detailed summary of each risk measure, please see our Documentation."
        />

        <Stack direction="row" gap="20px">
          <StyledButton variant="outlined" color="tertiary" fitContentWidth skipTypography onClick={exportToCsv}>
            <DownloadIcon />
            <Typography noWrap style={{ font: 'inherit' }}>
              Download Tear Sheet (.csv)
            </Typography>
          </StyledButton>
          <StyledContainedButton
            variant="contained"
            color="tertiary"
            fitContentWidth
            skipTypography
            onClick={generatePDF}
          >
            <DownloadIcon />
            <Typography noWrap style={{ font: 'inherit' }}>
              Download Full PDF Report
            </Typography>
          </StyledContainedButton>
        </Stack>
      </StyledHeaderWrapper>
      <StyledMeasuresWrapper>
        <StyledMeasureRow>
          <StyledMeasureText>{RowTitles.TOTAL_EXPOSURE}</StyledMeasureText>
          <StyledMeasureResult color="white.100">{totalExposure}</StyledMeasureResult>
          <StyledMeasureResult color="white.100">-</StyledMeasureResult>
        </StyledMeasureRow>
        <StyledMeasureRow>
          <StyledMeasureText>{RowTitles.EXPECTED_LOSS}</StyledMeasureText>
          <StyledMeasureResult color="white.100">{expectedLossUsd}</StyledMeasureResult>
          <StyledMeasureResult color="white.100">{expectedLossPct}</StyledMeasureResult>
        </StyledMeasureRow>
        <StyledMeasureRow>
          <StyledMeasureText>{RowTitles.UNEXPECTED_LOSS}</StyledMeasureText>
          <StyledMeasureResult color="white.100">{unexpectedLossUsd}</StyledMeasureResult>
          <StyledMeasureResult color="white.100">{unexpectedLossPct}</StyledMeasureResult>
        </StyledMeasureRow>
        <StyledMeasureRow>
          <StyledMeasureText>{RowTitles.CREDIT_VAR_95}</StyledMeasureText>
          <StyledMeasureResult color="white.100">{creditVar950Usd}</StyledMeasureResult>
          <StyledMeasureResult color="white.100">{creditVar950Pct}</StyledMeasureResult>
        </StyledMeasureRow>
        <StyledMeasureRow>
          <StyledMeasureText>{RowTitles.CREDIT_VAR_99}</StyledMeasureText>
          <StyledMeasureResult color="white.100">{creditVar990Usd}</StyledMeasureResult>
          <StyledMeasureResult color="white.100">{creditVar990Pct}</StyledMeasureResult>
        </StyledMeasureRow>
        <StyledMeasureRow>
          <StyledMeasureText>{RowTitles.CREDIT_VAR_999}</StyledMeasureText>
          <StyledMeasureResult color="white.100">{creditVar999Usd}</StyledMeasureResult>
          <StyledMeasureResult color="white.100">{creditVar999Pct}</StyledMeasureResult>
        </StyledMeasureRow>
      </StyledMeasuresWrapper>
    </StyledWrapper>
  );
};
