import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { SingleRow } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/SingleRow';
import {
  StyledGridContainer,
  StyledGridHeader,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SimulationRows/SimulationRows.styled';

export const SimulationRows = () => {
  const {
    rowsManager: { rowFields },
  } = useRiskSimulator();

  return (
    <>
      <StyledGridContainer>
        <StyledGridHeader align="left">Exchange ID</StyledGridHeader>
        <StyledGridHeader>Exposure ($)</StyledGridHeader>
        <StyledGridHeader>Recovery (%)</StyledGridHeader>
        <StyledGridHeader>1 YR-PD (%)</StyledGridHeader>
        <StyledGridHeader>Exposure (%)</StyledGridHeader>
        <StyledGridHeader />

        {rowFields.map((r, idx) => {
          return <SingleRow key={r.id} idx={idx} isCustom={r.isCustom} />;
        })}
      </StyledGridContainer>
    </>
  );
};
