import React from 'react';
import styled from '@emotion/styled';
import AlertsHeader from '@/components/molecules/Headers/AlertsHeader';
import Sidebar from '@/components/molecules/OldSidebar/OldSidebar';
import RatingsHeader from '@/components/molecules/Headers/RatingsHeader';
import EntityRatingsHeader from '@/components/molecules/Headers/EntityRatingsHeader';
import RiskSimulatorHeader from '@/components/molecules/Headers/RiskSimulatorHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface LayoutProps {
  children?: React.ReactNode;
  header?: null | 'alerts' | 'ratings' | 'entityRatings' | 'riskSimulator';
}

const Layout: React.FC<LayoutProps> = ({ children, header }) => {
  let headerToDisplay = null;
  switch (header) {
    case 'alerts':
      headerToDisplay = <AlertsHeader />;
      break;
    case 'ratings':
      headerToDisplay = <RatingsHeader />;
      break;
    case 'entityRatings':
      headerToDisplay = <EntityRatingsHeader />;
      break;
    case 'riskSimulator':
      headerToDisplay = <RiskSimulatorHeader />;
      break;
    default:
      headerToDisplay = null;
      break;
  }

  return (
    <Container className="default-layout">
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ width: 'calc(100% - 80px)', overflow: 'hidden' }}>
          {headerToDisplay && <>{headerToDisplay}</>}
          <StyledMain className="main">{children} </StyledMain>
        </div>
      </div>
    </Container>
  );
};

export default Layout;
