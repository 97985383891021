import {
  StyledSeverityIcon,
  StyledSeverityWrapper,
} from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon.styled';
import { adjustSeverityColor } from '@/helpers/severityLevelMethods';
import { TSeverity } from '@/types/severityLevel';

interface IAlertExclamationIconProps {
  severity: TSeverity;
}

export const AlertSeverityBarsIcon = ({ severity }: IAlertExclamationIconProps) => {
  if (severity === null) return null;

  const severityColor = adjustSeverityColor(severity);
  const isNoneSeverity = severity === 'none';

  return (
    <StyledSeverityWrapper color={severityColor}>
      {severity}
      {!isNoneSeverity && <StyledSeverityIcon color={severityColor} className={severity} />}
    </StyledSeverityWrapper>
  );
};
