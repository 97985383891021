export const DISABLE_CHART_EXPORTING_MENU = {
  exporting: {
    enabled: false,
  },
};

export const generateExportingSetupForColumnChart = (title: string) => ({
  exporting: {
    ...DISABLE_CHART_EXPORTING_MENU.exporting,
    filename: title,
    csv: {
      itemDelimiter: ';',
    },
    chartOptions: {
      plotOptions: {
        column: {
          dataLabels: {
            style: { color: '#000' },
          },
        },
      },
      title: {
        text: title,
        style: { color: '#000' },
      },
      xAxis: [
        {
          lineColor: '#000',
          labels: {
            style: {
              color: '#000',
            },
          },
          title: {
            style: {
              color: '#000',
            },
          },
        },
      ],
      yAxis: [
        {
          gridLineColor: '#AAA',
          gridLineWidth: 0.5,
          lineColor: '#000',
          lineWidth: 1,
          labels: {
            style: {
              color: '#000',
            },
          },
          title: {
            style: {
              color: '#000',
            },
          },
        },
      ],
    },
  },
});

export const RESET_ZOOM_BUTTON_POSITION = {
  zooming: {
    type: 'x',
    resetButton: {
      position: {
        align: 'left',
        x: 0,
        y: -40,
      },
    },
  },
};
