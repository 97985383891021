import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import styled from '@emotion/styled';
import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import { useAdjustSeverityZones } from '@/components/molecules/Charts/useAdjustSeverityZones';
import { ChartData } from '@/types/chartPoint';

const Figure = styled.figure`
  width: 100%;
  margin: 1em auto;
`;

interface ISeverityTrendProps {
  chartData: ChartData;
}

export const SeverityTrend = ({ chartData }: ISeverityTrendProps) => {
  const backgroundColor = '#101014';

  chartData.sort((a, b) => a.x - b.x);

  const allSeverityNumbers = [...chartData]
    .map((data) => {
      return data.y;
    })
    .filter((d): d is number => d !== null);

  const { zones } = useAdjustSeverityZones({ allSeverities: allSeverityNumbers, withShade: false });

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor,
      height: 50,
      type: 'area',
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        type: 'area',
        name: 'Trending Risk',
        data: chartData,
        enableMouseTracking: false,
        marker: {
          enabled: false,
        },
        zones: zones, // definition of the chart colors
      },
    ],
    time: {
      useUTC: false,
    },
    ...DISABLE_CHART_EXPORTING_MENU,
  };

  return (
    <Figure>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Figure>
  );
};
