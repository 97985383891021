import styled from '@emotion/styled';

export const StyledPageContainer = styled.div`
  display: flex;
`;

export const StyledAlertDetailsContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

export const StyledTextContainer = styled.div`
  padding: 16px 36px;
`;
