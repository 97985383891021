import { adjustSeverityChartColor } from '@/helpers/severityLevelMethods';

export const useAdjustSeverityZones = ({
  allSeverities,
  withShade = true,
}: {
  allSeverities: number[];
  withShade?: boolean;
}) => {
  const highestSeverity = Math.max(...allSeverities);
  const lowestSeverity = Math.min(...allSeverities);

  const adjustZonesColors = (minSeverity: number, maxSeverity: number) => {
    const totalArrLength = maxSeverity - minSeverity + 1;
    const minColor = adjustSeverityChartColor(minSeverity);
    const maxColor = adjustSeverityChartColor(maxSeverity);
    let lineStops;
    let areaStops;

    if (totalArrLength < 2 || totalArrLength > 5) {
      return [
        {
          color: adjustSeverityChartColor(lowestSeverity),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, `${maxColor}47`],
              [0.8956, `${maxColor}00`],
            ],
          },
        },
      ];
    }

    if (totalArrLength === 2) {
      lineStops = [
        [0.4, maxColor],
        [1, minColor],
      ];

      areaStops = [
        [0.1, `${maxColor}47`],
        [1, `${minColor}00`],
      ];
    }

    if (totalArrLength === 3) {
      lineStops = [
        [0, maxColor],
        [0.8, adjustSeverityChartColor(maxSeverity - 1)],
        [1, minColor],
      ];

      areaStops = [
        [0.3, `${maxColor}47`],
        [0.5, `${adjustSeverityChartColor(maxSeverity - 1)}24`],
        [1, `${minColor}00`],
      ];
    }

    if (totalArrLength === 4) {
      lineStops = [
        [0, maxColor],
        [0.4, adjustSeverityChartColor(maxSeverity - 1)],
        [0.8, adjustSeverityChartColor(minSeverity + 1)],
        [1, minColor],
      ];

      areaStops = [
        [0.2, `${maxColor}47`],
        [0.5, `${adjustSeverityChartColor(maxSeverity - 1)}36`],
        [0.8, `${adjustSeverityChartColor(maxSeverity - 2)}12`],
        [1, `${minColor}00`],
      ];
    }

    if (totalArrLength === 5) {
      lineStops = [
        [0.2, maxColor],
        [0.4, adjustSeverityChartColor(maxSeverity - 1)],
        [0.6, adjustSeverityChartColor(maxSeverity - 2)],
        [0.8, adjustSeverityChartColor(minSeverity + 1)],
        [1, minColor],
      ];

      areaStops = [
        [0.2, `${maxColor}47`],
        [0.4, `${adjustSeverityChartColor(maxSeverity - 1)}38`],
        [0.7, `${adjustSeverityChartColor(maxSeverity - 2)}29`],
        [0.8, `${adjustSeverityChartColor(minSeverity + 1)}1A`],
        [1, `${minColor}00`],
      ];
    }

    const linearGradient = {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
    };

    const line = {
      linearGradient,
      stops: lineStops,
    };

    const area = {
      linearGradient,
      stops: areaStops,
    };

    return [
      {
        color: line,
        fillColor: withShade ? area : {},
      },
    ];
  };

  const zones = adjustZonesColors(lowestSeverity, highestSeverity);

  return { zones };
};
