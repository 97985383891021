import { Typography } from '@mui/material';
import { DASHBOARD_TYPES } from '@/constants/dashboard_types';
import { DashboardCard } from '@/views/MonitoringView/components/DashboardCard/DashboardCard';
import { StyledCardsWrapper, StyledContainer } from '@/views/MonitoringView/MonitoringView.styled';

export const MonitoringView = () => {
  const generateDashboardsList = () => {
    return DASHBOARD_TYPES.map((type) => <DashboardCard key={type} type={type} />);
  };

  return (
    <StyledContainer>
      <div style={{ width: '100%' }}>
        <Typography variant="h2" color="white.100" align="center" textTransform="uppercase" noWrap>
          dashboards
        </Typography>
      </div>
      <div style={{ width: '100%' }}>
        <Typography color="white.100" align="center">
          Choose dashboard to view monitored entities.
        </Typography>
      </div>

      <StyledCardsWrapper>{generateDashboardsList()}</StyledCardsWrapper>
    </StyledContainer>
  );
};
