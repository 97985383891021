import { useMemo } from 'react';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { AppBar, Toolbar, Typography } from '@mui/material';
import InfoIcon from '@/assets/icons/info.svg';
import { useTracking } from '@/hooks/useTracking';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { ExportButton } from '@/components/atoms/Button/ExportButton';
import { useGetRatingsFile } from '@/api/ratings/ratings.hooks';
import { downloadFile } from '@/helpers/file';
import { useRatings } from '@/providers/RatingsListContextProvider/RatingsListContext.hooks';

const StyledHeader = styled(AppBar)`
  height: 99px;
  width: calc(100vw - 100px);
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

const LastUpdateContainer = styled.div`
  align-self: flex-end;
  padding-bottom: 7px;
  margin-left: 27px;
  opacity: 0.5;
`;

export const RatingsHeaderPermitted = () => {
  const { ratings, isLoadingRatings } = useRatings();
  const { downloadRatingsFile } = useGetRatingsFile();

  const lastUpdate = useMemo(() => {
    if (!isLoadingRatings && ratings && ratings.timestamp) {
      return format(new Date(ratings.timestamp.split('T')[0]), 'dd/MM/yy');
    }

    return '';
  }, [isLoadingRatings, ratings]);

  const { trackEvent } = useTracking();

  const handleFileDownloadClick = () => {
    downloadRatingsFile(undefined, {
      onSuccess: (data) => {
        downloadFile({
          data: data,
          fileName: 'agio-statistical-ratings.csv',
          fileType: 'text/csv',
        });
        trackEvent('Ratings file download', {});
      },
      onError: (error) => {
        console.error(error.message);
      },
    });
  };

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
              Statistical Ratings
            </Typography>
          </div>
          <Tooltip
            arrow
            title="Statistical Ratings measures a counterparty’s estimated probability of default (PD) over the following 12 months. Default is defined as the initiation of legal proceedings because a counterparty is unable to repay its outstanding debts or obligations. In practice, this will typically be preceded by the halting of withdrawals and may ultimately lead to the cessation of day-to-day activities."
          >
            <img src={InfoIcon} alt="Info icon" />
          </Tooltip>
          {lastUpdate && <LastUpdateContainer>Last update: {lastUpdate}</LastUpdateContainer>}
        </div>
        <ExportButton variant="icon" onClick={handleFileDownloadClick} tooltipText="Export Rating CSV" />
      </Toolbar>
    </StyledHeader>
  );
};
