import { FormProvider } from 'react-hook-form';
import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { CounterpartyExposures } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/CounterpartyExposures';
import { ContagionLevel } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/ContagionLevel/ContagionLevel';
import { SimulatorPanel } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/SimulatorPanel/SimulatorPanel';
import { StyledSimulatorWrapper } from '@/views/RiskSimulatorPortfolioView/components/Simulator/Simulator.styled';

export const Simulator = () => {
  const { riskSimulatorMethods, handleRiskSimulatorFormSubmit } = useRiskSimulator();

  return (
    <FormProvider {...riskSimulatorMethods}>
      <form onSubmit={handleRiskSimulatorFormSubmit}>
        <StyledSimulatorWrapper>
          <CounterpartyExposures />

          <ContagionLevel />

          <SimulatorPanel />
        </StyledSimulatorWrapper>
      </form>
    </FormProvider>
  );
};
