import React from 'react';
import { Link, Typography } from '@mui/material';
import { StyledContainer } from '@/views/MonitoringSettingsView/MonitoringSettingsView.styled';
import { EntitiesMonitoringSection } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringSection/EntitiesMonitoringSection';
import { EntitiesEmailNotificationsSection } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsSection/EntitiesEmailNotificationsSection';

export const MonitoringSettingsView = () => {
  return (
    <StyledContainer>
      <EntitiesMonitoringSection />

      <EntitiesEmailNotificationsSection />

      <Typography color="white.100" align="center" fontStyle="italic" pt={10} pl={30} pr={30}>
        If you&apos;re interested in tracking something not on the current list, just{' '}
        <Link href="mailto:alerts@agioratings.io" underline="hover">
          send us
        </Link>{' '}
        a request, and we&apos;ll be happy to assist!
      </Typography>
    </StyledContainer>
  );
};
