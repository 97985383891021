import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { isObjectWithKey } from '@/helpers/typeGuards';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { useGetEntitiesListByAdmin } from '@/api/adminEntityIds/adminEntityIds.hooks';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import { CreateNewEntityModal } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/CreateNewEntityModal/CreateNewEntityModal';
import { EntitiesList } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/EntitiesList/EntitiesList';
import {
  StyledButtonWrapper,
  StyledAutocomplete,
  StyledPaper,
} from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/AdminPanelEntitiesList.styled';
import { EAdminPaths, ERoutes } from '@/types/routes';
import { IGetEntitiesListRequest } from '@/api/adminEntityIds/adminEntityIds.types';

export const AdminPanelEntitiesList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { exchangesDisplayedToAdmin } = useEntitiesList();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(INITIAL_PAGE_SIZE);

  const adminEntitiesListRequestPayload: Partial<IGetEntitiesListRequest> = useMemo(() => {
    return {
      page,
      size,
    };
  }, [page, size]);

  const { isLoading, entitiesList, error, refetch } = useGetEntitiesListByAdmin(adminEntitiesListRequestPayload);

  const totalEntities = useMemo(() => {
    return entitiesList?.total || 0;
  }, [entitiesList]);

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handlePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(+event.target.value);
    setPage(1);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <StyledAutocomplete
        id="entities-list"
        disablePortal
        options={exchangesDisplayedToAdmin}
        renderInput={(params) => <TextField {...params} label="Entities" />}
        PaperComponent={StyledPaper}
        onChange={(event, newValue) => {
          if (isObjectWithKey(newValue, 'id')) {
            navigate(`${ERoutes.ADMIN}/${EAdminPaths.ENTITIES}/${newValue.id}`);
          }
        }}
      />

      <EntitiesList
        entitiesList={entitiesList?.items || []}
        size={size}
        handlePageSize={handlePageSize}
        page={page}
        handlePageChange={handlePageChange}
        totalEntities={totalEntities}
        entitiesListError={error}
        isLoadingEntitiesList={isLoading}
      />
      <StyledButtonWrapper>
        <AdminButton onClick={handleOpenModal}>Create new entity</AdminButton>
      </StyledButtonWrapper>

      <CreateNewEntityModal isOpen={isModalOpen} closeModal={handleCloseModal} refetchListOfEntities={refetch} />
    </>
  );
};
