import { useCallback, useMemo, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { TRiskIndicator, TSeverity } from '@/types/severityLevel';
import { adjustRiskIndicatorSortingIndex, adjustSeverityChartPoint } from '@/helpers/severityLevelMethods';

export const useSortingManager = () => {
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'exampleField', sort: 'asc' }]);

  const updateSortModel = (newModel: GridSortModel) => setSortModel(newModel);

  const sortingSeverityModel = sortModel.find((model) => model.field === 'severity');
  const sortingRiskIndicatorModel = sortModel.find((model) => model.field === 'riskIndicator');

  const isSeverityAscendant = useMemo(
    () => !!sortingSeverityModel && sortingSeverityModel.sort === 'asc',
    [sortingSeverityModel],
  );

  const isIndicatorAscendant = useMemo(
    () => !!sortingRiskIndicatorModel && sortingRiskIndicatorModel.sort === 'asc',
    [sortingRiskIndicatorModel],
  );

  const severityComparator = useCallback(
    (v1: TSeverity, v2: TSeverity) => {
      const severityCode1 = adjustSeverityChartPoint(v1);
      const severityCode2 = adjustSeverityChartPoint(v2);

      if (severityCode1 === null && severityCode2 === null) return 0;
      if (severityCode1 === null) return isSeverityAscendant ? 1 : -1;
      if (severityCode2 === null) return isSeverityAscendant ? -1 : 1;

      return severityCode1 - severityCode2;
    },
    [isSeverityAscendant],
  );

  const riskIndicatorComparator = useCallback(
    (v1: TRiskIndicator | null, v2: TRiskIndicator | null) => {
      const riskIndicatorCode1 = adjustRiskIndicatorSortingIndex(v1);
      const riskIndicatorCode2 = adjustRiskIndicatorSortingIndex(v2);

      if (riskIndicatorCode1 === null && riskIndicatorCode2 === null) return 0;
      if (riskIndicatorCode1 === null) return isIndicatorAscendant ? 1 : -1;
      if (riskIndicatorCode2 === null) return isIndicatorAscendant ? -1 : 1;

      return riskIndicatorCode1 - riskIndicatorCode2;
    },
    [isIndicatorAscendant],
  );

  return {
    sortModel,
    updateSortModel,
    severityComparator,
    riskIndicatorComparator,
  };
};
