import styled from '@emotion/styled';

export const StyledImg = styled.img<{ height: string }>`
  height: ${({ height }) => height};
`;

const DEFAULT_TOTAL_PADDING_LOGO_IMAGES = 16;

export const StyledPlaceholder = styled.div<{ height: string }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: ${({ height }) => `calc(${height} - ${DEFAULT_TOTAL_PADDING_LOGO_IMAGES}px)`};
  height: ${({ height }) => `calc(${height} - ${DEFAULT_TOTAL_PADDING_LOGO_IMAGES}px)`};
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  overflow: hidden;
`;
