import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { ICreateEntityForm } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/CreateNewEntityForm/utils/types';
import {
  defaultCreateEntityFormValues,
  SIMILARWEB_DOMAIN_SEPARATOR,
} from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/CreateNewEntityForm/utils/constants';
import { useCreateEntity } from '@/api/adminEntityIds/adminEntityIds.hooks';

interface IUseCreateEntityFormProps {
  closeModal: () => void;
  refetchListOfEntities: () => void;
}

export const useCreateEntityForm = ({ closeModal, refetchListOfEntities }: IUseCreateEntityFormProps) => {
  const { createEntity } = useCreateEntity();

  const createEntityMethods = useForm<ICreateEntityForm>({
    defaultValues: defaultCreateEntityFormValues,
  });

  const { handleSubmit } = createEntityMethods;

  const onCreateEntitySubmit = useCallback(
    async (data: ICreateEntityForm) => {
      const payload = {
        ...(data.agioId ? { agioId: data.agioId } : {}),
        ...(data.analystNotes ? { analystNotes: data.analystNotes } : {}),
        ...(data.arkhamId ? { arkhamId: data.arkhamId } : {}),
        ...(data.coingeckoId ? { coingeckoId: data.coingeckoId } : {}),
        ...(data.cryptoCompareId ? { cryptoCompareId: data.cryptoCompareId } : {}),
        ...(data.cryptoCompareName ? { cryptoCompareName: data.cryptoCompareName } : {}),
        ...(data.displayName ? { displayName: data.displayName } : {}),
        ...(data.glassnodeId ? { glassnodeId: data.glassnodeId } : {}),
        ...(data.similarwebDomain
          ? { similarwebDomain: data.similarwebDomain.split(SIMILARWEB_DOMAIN_SEPARATOR) }
          : {}),
        ...(data.type ? { type: data.type } : {}),
        ...(data.subtype ? { subtype: data.subtype } : {}),
        ...(data.status ? { status: data.status } : {}),
        ...(data.isRated ? { isRated: data.isRated } : {}),
        ...(data.visibleToCustomer ? { visibleToCustomer: data.visibleToCustomer } : {}),
        ...(data.linkedinQuery ? { linkedinQuery: data.linkedinQuery } : {}),
      };

      createEntity(payload, {
        onSuccess: () => {
          closeModal();
          refetchListOfEntities();
        },
        onError: (error) => {
          console.error('Edit entity failed: ', error.message);
        },
      });
    },
    [closeModal, createEntity, refetchListOfEntities],
  );

  const handleCreateEntityFormSubmit = handleSubmit(onCreateEntitySubmit);

  return {
    createEntityMethods,
    handleCreateEntityFormSubmit,
  };
};
