import { isArray } from 'lodash';
import { RowInput } from 'jspdf-autotable';
import { formatFloatToPercent, formatToUSD } from '@/helpers/helpers';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { riskMeasuresRowTitles } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/utils/constants';
import { RowTitles } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/utils/types';
import { IResultOfRiskSimulation, IRow } from '@/views/RiskSimulatorPortfolioView/utils/types';

export const generateCounterpartyData = (rows: IRow[]) => {
  return rows.map((row) => {
    const { entity, pd, percentExposure, exposure, recovery, isCustom } = row;

    const exchange = isCustom ? getFullExchangeName(entity) + '*' : getFullExchangeName(entity);

    return [
      exchange,
      formatToUSD(exposure),
      formatFloatToPercent(recovery),
      formatFloatToPercent(pd),
      formatFloatToPercent(percentExposure),
    ];
  });
};

export const generateRiskMeasuresData = (resultOfRiskSimulation: IResultOfRiskSimulation, isPdf = false) => {
  return riskMeasuresRowTitles.map((title) => {
    const generatedRows = adjustRowValues(title, resultOfRiskSimulation, isPdf);

    return isPdf ? generatedRows : generatedRows.join(';');
  });
};

const adjustRowValues = (title: RowTitles, resultOfRiskSimulation: IResultOfRiskSimulation, isPdf: boolean) => {
  const {
    totalExposure,
    expectedLossUsd,
    expectedLossPct,
    unexpectedLossUsd,
    unexpectedLossPct,
    creditVar950Pct,
    creditVar990Pct,
    creditVar999Pct,
    creditVar950Usd,
    creditVar990Usd,
    creditVar999Usd,
  } = resultOfRiskSimulation;

  switch (title) {
    case RowTitles.TOTAL_EXPOSURE:
      return isPdf ? [title, totalExposure, '-'] : [title, totalExposure];
    case RowTitles.EXPECTED_LOSS:
      return [title, expectedLossUsd, expectedLossPct];
    case RowTitles.UNEXPECTED_LOSS:
      return [title, unexpectedLossUsd, unexpectedLossPct];
    case RowTitles.CREDIT_VAR_95:
      return [title, creditVar950Usd || '', creditVar950Pct || ''];
    case RowTitles.CREDIT_VAR_99:
      return [title, creditVar990Usd || '', creditVar990Pct || ''];
    case RowTitles.CREDIT_VAR_999:
      return [title, creditVar999Usd || '', creditVar999Pct || ''];
  }
};

export const isRowInput = (data: unknown[]): data is RowInput[] => {
  return data
    .map((singleData) => {
      return !!isArray(singleData);
    })
    .every((singleData) => singleData);
};
