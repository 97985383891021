import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useAnomaliesEmailNotificationsForm } from '@/views/MonitoringSettingsView/utils/useAnomaliesEmailNotificationsForm';
import Button from '@/components/atoms/Button/Button';
import { EntitiesEmailNotificationsForm } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/EntitiesEmailNotificationsForm';
import {
  StyledForm,
  StyledButtonContainer,
} from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsSection/EntitiesEmailNotificationsSection.styled';

export const EntitiesEmailNotificationsSection = () => {
  const {
    anomaliesEmailNotificationsMethods,
    handleAnomaliesEmailNotificationsFormSubmit,
    isEditEmailSettingsLoading,
    isGetEmailSettingsLoading,
  } = useAnomaliesEmailNotificationsForm();

  return (
    <FormProvider {...anomaliesEmailNotificationsMethods}>
      <StyledForm onSubmit={handleAnomaliesEmailNotificationsFormSubmit}>
        <EntitiesEmailNotificationsForm
          formMethods={anomaliesEmailNotificationsMethods}
          isGetEmailSettingsLoading={isGetEmailSettingsLoading}
        />
        <StyledButtonContainer>
          <Button
            type="submit"
            variant="contained"
            fitContentWidth
            sx={{ minWidth: '142px' }}
            disabled={isEditEmailSettingsLoading}
          >
            Save
          </Button>
        </StyledButtonContainer>
      </StyledForm>
    </FormProvider>
  );
};
