import { Link, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@/assets/icons/help.svg';
import RedirectIcon from '@/assets/icons/redirect-icon.svg';
import BookIcon from '@/assets/icons/book.svg';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import {
  StyledButton,
  StyledButtonsWrapper,
  StyledHeader,
  StyledTitleWrapper,
  StyledToolbar,
} from '@/components/molecules/Headers/RiskSimulatorHeader.styled';
import { useGetRiskDocumentation } from '@/api/documentation/documentation.hooks';
import { useTracking } from '@/hooks/useTracking';

const RiskSimulatorHeader = () => {
  const { fetchRiskDocumentation } = useGetRiskDocumentation();
  const { trackEvent } = useTracking();

  const handleDocumentationClick = () => {
    trackEvent('Whitepaper Clicked', {});
    fetchRiskDocumentation(undefined, {
      onSuccess: ({ url }) => {
        window.open(url, '_blank');
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };
  return (
    <StyledHeader position="static">
      <StyledToolbar>
        <StyledTitleWrapper>
          <Typography variant="h4" noWrap>
            Risk Simulator
          </Typography>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title="The Risk Simulator is Agio Ratings’ tool to measure the overall credit risk of having exposures on multiple centralized exchanges or other crypto counterparties."
          >
            <img src={HelpIcon} alt="Help icon" />
          </Tooltip>
        </StyledTitleWrapper>

        <StyledButtonsWrapper onClick={handleDocumentationClick}>
          {/*<Tooltip*/}
          {/*  arrow*/}
          {/*  TransitionComponent={Zoom}*/}
          {/*  placement="bottom"*/}
          {/*  title="Risk Simulator Onboarding - Click to run onboarding process."*/}
          {/*>*/}
          {/*  <StyledButton variant="outlined" color="tertiary" skipTypography>*/}
          {/*    <img src={BookIcon} alt="Book icon" />*/}
          {/*    Quick Start Guide*/}
          {/*  </StyledButton>*/}
          {/*</Tooltip>*/}

          <Tooltip
            arrow
            TransitionComponent={Zoom}
            placement="left"
            title="Risk Simulator Documentation - Click to open in a new tab."
          >
            <Link
              // href="https://agio-ratings-ltd.gitbook.io/portfolio-loss-calculator/zkniaMDSXS1fORjQjIJy"
              underline="none"
              target="_blank"
            >
              <StyledButton variant="outlined" color="tertiary" skipTypography>
                <img src={RedirectIcon} alt="Redirect icon" />
                Documentation
              </StyledButton>
            </Link>
          </Tooltip>
        </StyledButtonsWrapper>
      </StyledToolbar>
    </StyledHeader>
  );
};

export default RiskSimulatorHeader;
