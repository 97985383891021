import styled from '@emotion/styled';
import { Paper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { BaseTextFieldProps } from '@components/atoms/BaseTextField/BaseTextField';

export const StyledMuiTextInput = styled(TextField)<BaseTextFieldProps>`
  display: block;
  width: 100%;

  .MuiInputBase-root {
    width: 100%;
    height: 100%;
    color: #f5f5f5;
    font-size: 13px;

    .MuiInputBase-input.MuiOutlinedInput-input {
      text-align: right;
    }

    .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
      -webkit-text-fill-color: #f5f5f5;
    }

    .MuiInputBase-input.MuiOutlinedInput-input::placeholder {
      color: #f5f5f580;
      opacity: 1;
    }

    &:hover fieldset {
      border-color: #5d51b8;
    }

    &.Mui-disabled fieldset {
      border: none;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    text-align: center;
  }

  .MuiFormHelperText-root {
    margin: 0;
    padding-block: 3px;
  }

  .MuiFormHelperText-root.Mui-error {
    color: red;
  }
`;

export const StyledMuiTextInputAlignedLeft = styled(StyledMuiTextInput)`
  .MuiInputBase-root {
    .MuiInputBase-input.MuiOutlinedInput-input {
      text-align: left;
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  //Button white text
  .MuiButtonBase-root {
    color: #fff;

    //Buttons icons color
    svg {
      fill: rgba(255, 255, 255, 0.54);
    }
  }
`;

export const StyledPaper = styled(Paper)`
  background: #1b1b1e;
  border-radius: 4px;
  color: #fff;

  .MuiAutocomplete-noOptions {
    color: #fff;
  }
`;
