import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { Ratings } from '@/views/RatingsView/Ratings';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';

export const RatingsPage = () => {
  const { shouldDisplayRisks } = usePermissionsManager();

  if (!shouldDisplayRisks) {
    return <NoPermissionsView isRatingsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <Ratings />
    </ErrorBoundary>
  );
};
