import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Typography } from '@mui/material';
import Loader from '@/components/atoms/Loader/Loader';
import { NotificationsModeRadioGroup } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/components/NotificationsModeRadioGroup/NotificationsModeRadioGroup';
import { EntitiesToEmailNotification } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/components/EntitiesToEmailNotification/EntitiesToEmailNotification';
import { StyledWrapper } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/EntitiesEmailNotificationsForm.styled';
import { IAnomaliesEmailNotificationsForm } from '@/views/MonitoringSettingsView/utils/types';

export interface IEntitiesEmailNotificationsFormProps {
  formMethods: UseFormReturn<IAnomaliesEmailNotificationsForm>;
  isGetEmailSettingsLoading: boolean;
}

export const EntitiesEmailNotificationsForm = ({
  formMethods,
  isGetEmailSettingsLoading,
}: IEntitiesEmailNotificationsFormProps) => {
  const { notificationsMode } = formMethods.watch();

  const generateFormContent = () => {
    if (isGetEmailSettingsLoading) {
      return <Loader />;
    }

    return (
      <>
        <NotificationsModeRadioGroup formMethods={formMethods} />

        {notificationsMode === 'select' && <EntitiesToEmailNotification formMethods={formMethods} />}
      </>
    );
  };

  return (
    <StyledWrapper>
      <Typography variant="h2" color="white.100" fontWeight="semibold">
        Email notifications
      </Typography>
      <Typography color="white.100" fontSize="16px">
        Select entities for anomalies email notifications.
      </Typography>

      {generateFormContent()}
    </StyledWrapper>
  );
};
