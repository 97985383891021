import { Calculations } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/Calculations/Calculations';
import { SimulationParameters } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulationParameters/SimulationParameters';
import { StyledContainer } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/GeneratedResultView/GeneratedResultView.styled';

export const GeneratedResultView = () => {
  return (
    <StyledContainer>
      <Calculations />
      <SimulationParameters />
    </StyledContainer>
  );
};
