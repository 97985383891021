import { RefObject, useMemo } from 'react';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import { mapGeneratedDataForHeatmap } from '@/helpers/stats';
import { SectionTitle } from '@/views/RiskSimulatorPortfolioView/components/SectionTitle/SectionTitle';
import { CorrelationsChart } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/components/CorrelationsChart/CorrelationsChart';
import {
  StyledHeaderWrapper,
  StyledSection,
} from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/SimulatedCharts.styled';
import { IRow } from '@/views/RiskSimulatorPortfolioView/utils/types';

interface ICorrelationsSectionProps {
  correlation: number;
  rows: Pick<IRow, 'entity' | 'pd'>[];
  correlationRef: RefObject<HighchartsReactRefObject>;
}

export const CorrelationsSection = ({ correlation, rows, correlationRef }: ICorrelationsSectionProps) => {
  const entitiesForAxes = useMemo(() => rows.filter(({ entity }) => !!entity), [rows]);

  const dataCorrelationMultiplierChart = useMemo(() => {
    return mapGeneratedDataForHeatmap(entitiesForAxes, correlation);
  }, [correlation, entitiesForAxes]);

  return (
    <StyledSection>
      <StyledHeaderWrapper>
        <SectionTitle
          title="Pairwise Correlations of Default"
          tooltipDescription="A correlation matrix where each entry describes the pairwise correlation of default between two given counterparties. Each correlation depends on the individual PDs as well as the level of contagion risk chosen by the user. A higher contagion risk corresponds to higher correlation whilst no contagion risk corresponds to no correlation."
        />
      </StyledHeaderWrapper>

      <CorrelationsChart
        chartRef={correlationRef}
        chartData={dataCorrelationMultiplierChart}
        axisData={entitiesForAxes}
      />
    </StyledSection>
  );
};
