import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper } from '@mui/material';

export const StyledButtonWrapper = styled.div`
  width: 300px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  border-radius: 4px;
  background-color: #101014;
  width: 300px;

  //Input border basic + on hover
  & fieldset,
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.54);
  }

  //Button white text
  .MuiButtonBase-root {
    color: #fff;
  }

  //Input white text
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
  }

  //Main label color behavior
  label {
    color: rgba(255, 255, 255, 0.54);
    transition: color 0.3s;
  }
`;

export const StyledPaper = styled(Paper)`
  background: #1b1b1e;
  border-radius: 4px;
  color: #fff;

  .MuiAutocomplete-noOptions {
    color: #fff;
  }
`;
