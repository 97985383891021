import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { assertIsDefined, assertIsDefinedAndNullable } from '@/helpers/assertions';
import { sortAlphabetically } from '@/helpers/helpers';
import { useRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/utils/useRiskSimulatorForm';
import { useAutocompleteOptionsManager } from '@/views/RiskSimulatorPortfolioView/utils/useAutocompleteOptionsManager';
import { useFormRowsManager } from '@/views/RiskSimulatorPortfolioView/utils/useFormRowsManager';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { IRiskSimulatorContext } from '@/views/RiskSimulatorPortfolioView/utils/types';
import { useCsvRiskSimulatorManager } from '@/views/RiskSimulatorPortfolioView/utils/useCsvRiskSimulatorManager';
import { useDatePickerManager } from '@/views/RiskSimulatorPortfolioView/utils/useDatePickerManager';

export const RiskSimulatorContext = createContext<Partial<IRiskSimulatorContext>>({});

interface IRiskSimulatorProviderProps {
  fetchedRatings: IStatisticalRatingEntity[];
}

export const RiskSimulatorProvider = ({ children, fetchedRatings }: PropsWithChildren<IRiskSimulatorProviderProps>) => {
  const {
    riskSimulatorMethods,
    handleRiskSimulatorFormSubmit,
    resultOfRiskSimulation,
    clearResultsOfRiskSimulation,
    isSimulationLoading,
    parametersOfDisplayedSimulation,
  } = useRiskSimulatorForm();

  const { datePickerManager } = useDatePickerManager({
    ratings: fetchedRatings,
    formMethods: riskSimulatorMethods,
  });

  const watchedRowsValues = riskSimulatorMethods.watch('rows');

  const totalExposure = watchedRowsValues.reduce((acc, row) => acc + Number(row.exposure), 0);

  const { rowsManager } = useFormRowsManager({
    formMethods: riskSimulatorMethods,
  });

  const exchangeList = useMemo(() => {
    const exchanges = fetchedRatings.map((sr) => sr.entity);

    return sortAlphabetically(exchanges);
  }, [fetchedRatings]);

  const { autocompleteManager } = useAutocompleteOptionsManager({
    watchedRowsValues,
    exchangeList,
    rowFields: rowsManager.rowFields,
  });

  const handleResetForm = () => {
    riskSimulatorMethods.reset();
    clearResultsOfRiskSimulation();
    autocompleteManager.clearSelectedOptions();
  };

  const VALUE: IRiskSimulatorContext = {
    riskSimulatorMethods,
    handleRiskSimulatorFormSubmit,
    resultOfRiskSimulation,
    isSimulationLoading,
    parametersOfDisplayedSimulation,

    ratings: fetchedRatings,

    datePickerManager,
    watchedRowsValues,
    totalExposure,
    rowsManager,
    autocompleteManager,
    handleResetForm,
  };

  useCsvRiskSimulatorManager({
    ratings: fetchedRatings,
    exchangeList,
    timestamp: datePickerManager.formattedSelectedDate,
    replaceRows: rowsManager.replaceRows,
  });

  return <RiskSimulatorContext.Provider value={VALUE}>{children}</RiskSimulatorContext.Provider>;
};

export const useRiskSimulator = () => {
  const {
    riskSimulatorMethods,
    handleRiskSimulatorFormSubmit,
    resultOfRiskSimulation,
    isSimulationLoading,
    parametersOfDisplayedSimulation,

    ratings,

    datePickerManager,
    watchedRowsValues,
    totalExposure,
    rowsManager,
    autocompleteManager,
    handleResetForm,
  } = useContext(RiskSimulatorContext);

  assertIsDefined(riskSimulatorMethods, '"IRiskSimulatorContext.riskSimulatorMethods has to be defined!"');
  assertIsDefined(
    handleRiskSimulatorFormSubmit,
    '"IRiskSimulatorContext.handleRiskSimulatorFormSubmit has to be defined!"',
  );
  assertIsDefinedAndNullable(
    resultOfRiskSimulation,
    '"IRiskSimulatorContext.resultOfRiskSimulation has to be defined!"',
  );
  assertIsDefined(isSimulationLoading, '"IRiskSimulatorContext.isSimulationLoading has to be defined!"');
  assertIsDefinedAndNullable(
    parametersOfDisplayedSimulation,
    '"IRiskSimulatorContext.parametersOfDisplayedSimulation has to be defined!"',
  );
  assertIsDefined(ratings, '"IRiskSimulatorContext.ratings has to be defined!"');
  assertIsDefined(datePickerManager, '"IRiskSimulatorContext.datePickerManager has to be defined!"');
  assertIsDefined(watchedRowsValues, '"IRiskSimulatorContext.watchedRowsValues has to be defined!"');
  assertIsDefined(totalExposure, '"IRiskSimulatorContext.totalExposure has to be defined!"');
  assertIsDefined(rowsManager, '"IRiskSimulatorContext.rowsManager has to be defined!"');
  assertIsDefined(autocompleteManager, '"IRiskSimulatorContext.autocompleteManager has to be defined!"');
  assertIsDefined(handleResetForm, '"IRiskSimulatorContext.handleResetForm has to be defined!"');

  return {
    riskSimulatorMethods,
    handleRiskSimulatorFormSubmit,
    resultOfRiskSimulation,
    isSimulationLoading,
    parametersOfDisplayedSimulation,
    ratings,
    datePickerManager,
    watchedRowsValues,
    totalExposure,
    rowsManager,
    autocompleteManager,
    handleResetForm,
  };
};
