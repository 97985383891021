import { useMemo, useState } from 'react';
import { mapSeverityChartData } from '@/helpers/mapChartsData';
import {
  isTrendingRiskOptionKey,
  TrendingRiskOptionKey,
} from '@/views/EntityMonitoringView/components/TrendingRiskChart/utils/types';
import { TSeverity } from '@/types/severityLevel';
import { ChartData } from '@/types/chartPoint';

interface SeverityData {
  timestamp: string;
  value: TSeverity;
}

interface IUseTrendingRiskChartModeManagerProps {
  lastWeekAnomalies: SeverityData[];
  lastDayAnomalies: SeverityData[];
}

export const useTrendingRiskChartModeManager = ({
  lastWeekAnomalies,
  lastDayAnomalies,
}: IUseTrendingRiskChartModeManagerProps) => {
  const [selectedTrendingRiskChart, setSelectedTrendingRiskChart] = useState<TrendingRiskOptionKey>('lastDay');

  const handleTrendingRiskChartChange = (value: string) => {
    if (isTrendingRiskOptionKey(value)) {
      setSelectedTrendingRiskChart(value);
    } else {
      console.error("The value doesn't below to the trending risk chart options.");
    }
  };

  const weekChartDataset = mapSeverityChartData(lastWeekAnomalies);
  const dayChartDataset = mapSeverityChartData(lastDayAnomalies);

  const getChartData = (selectedTrendingRiskChart: TrendingRiskOptionKey): ChartData => {
    switch (selectedTrendingRiskChart) {
      case 'lastWeek':
        return weekChartDataset;
      case 'lastDay':
        return dayChartDataset;
      default:
        return [];
    }
  };

  const isHourlyChart = selectedTrendingRiskChart === 'lastDay';

  const generatedChartData = useMemo(() => getChartData(selectedTrendingRiskChart), [selectedTrendingRiskChart]);

  return { selectedTrendingRiskChart, handleTrendingRiskChartChange, isHourlyChart, generatedChartData };
};
