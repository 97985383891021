import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { formatToUSD, amountToNumber, formatDateToUK } from '@/helpers/helpers';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { isSeverityLevelKey } from '@/helpers/typeGuards';
import { mapChainData } from '@/helpers/mapChartsData';
import { severityLevels } from '@/constants/InitialState/severityLevel';
import { useGetAlertByIdByAdmin } from '@/api/adminAlerts/adminAlerts.hooks';
import ActionsSection from '@/components/molecules/ActionsSection/ActionsSection';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import WrapperCharts from '@/components/molecules/Charts/WrapperCharts';
import Loader from '@/components/atoms/Loader/Loader';
import AdminPanelUpdatesList from '@/components/molecules/AdminPanelUpdatesList/AdminPanelUpdatesList';
import Text from '@/components/atoms/Text/Text';
import { SeverityLevelKey } from '@/types/severityLevel';
import {
  StyledAlertDetailsContainer,
  StyledChartsWrapper,
  StyledContainer,
  StyledQuill,
  StyledSeverityContainer,
  StyledWrapperInput,
} from './AdminPanelAlertDetails.styled';
import 'react-quill/dist/quill.snow.css';

const AlertDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [insight, setInsight] = useState('');
  const [severity, setSeverity] = useState<SeverityLevelKey>('none');

  const { fetchedAlert, isLoading, error } = useGetAlertByIdByAdmin({ alertId: id || '' });

  useEffect(() => {
    if (!fetchedAlert) return;

    fetchedAlert.insight && setInsight(fetchedAlert.insight);

    fetchedAlert.severity !== null && setSeverity(fetchedAlert.severity);
  }, [fetchedAlert]);

  const handleInsightChange = (value: string) => {
    setInsight(value);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) return <Typography color="white.100">Error getting alert: {error.message}</Typography>;

  if (!fetchedAlert) return <Typography color="white.100">There is no alert data to display.</Typography>;

  const formattedData = fetchedAlert.chainData ? mapChainData(fetchedAlert.chainData) : [];
  const formattedNetFlowData = mapChainData(fetchedAlert.netflowData);
  const formattedTotalFlowData = mapChainData(fetchedAlert.totalFlowData);

  const anomalyChainData = formattedData.sort((a, b) => a.x - b.x)[formattedData.length - 1];

  return (
    <StyledAlertDetailsContainer>
      <Text fontSize="20px">Alert Details</Text>
      <form>
        <StyledContainer>
          <div>
            <Text fontSize="14px">Exchange</Text>
            <StyledWrapperInput>
              <BaseTextField
                name="exchange"
                borderColor="#fff"
                value={fetchedAlert.metadata?.exchange || ''}
                disabled
              />
            </StyledWrapperInput>
          </div>
          <div>
            <Text fontSize="14px">Timestamp</Text>
            <StyledWrapperInput>
              <BaseTextField
                name="timestamp"
                borderColor="#fff"
                value={formatDateToUK(fetchedAlert.timestamp)}
                disabled
              />
            </StyledWrapperInput>
          </div>
          <div>
            <Text fontSize="14px">Variable</Text>
            <StyledWrapperInput>
              <BaseTextField
                name="variable"
                borderColor="#fff"
                value={mapMetadataType(fetchedAlert.metadata?.type)}
                disabled
              />
            </StyledWrapperInput>
          </div>
          <div>
            <Text fontSize="14px">z-Score</Text>
            <StyledWrapperInput>
              <BaseTextField name="z_score" borderColor="#fff" value={fetchedAlert.zScore.toString()} disabled />
            </StyledWrapperInput>
          </div>
          <div>
            <Text fontSize="14px">%Change</Text>
            <StyledWrapperInput>
              <BaseTextField
                name="change_percentage"
                borderColor="#fff"
                value={fetchedAlert.changePercentage?.toString()}
                disabled
              />
            </StyledWrapperInput>
          </div>
          <div>
            <Text fontSize="14px">Change Amount</Text>
            <StyledWrapperInput>
              <BaseTextField
                name="change_amount"
                borderColor="#fff"
                value={
                  fetchedAlert.changeAmount ? formatToUSD(amountToNumber(fetchedAlert.changeAmount.toString())) : ''
                }
                disabled
              />
            </StyledWrapperInput>
          </div>
          <div>
            <Text fontSize="14px">Entity type</Text>
            <StyledWrapperInput>
              <BaseTextField
                name="entity_type"
                borderColor="#fff"
                value={fetchedAlert.metadata?.entityType || ''}
                disabled
              />
            </StyledWrapperInput>
          </div>
        </StyledContainer>

        <StyledSeverityContainer>
          <Typography color="white.100" fontSize="20px" fontWeight="600">
            Severity
          </Typography>

          <FormControl>
            <RadioGroup
              row
              aria-labelledby="severity-radio-group"
              value={severity}
              onChange={(e) => {
                if (isSeverityLevelKey(e.target.value)) {
                  setSeverity(e.target.value);
                }
              }}
              name="severity-radio-group"
            >
              {severityLevels.map((severity) => {
                return (
                  <FormControlLabel
                    key={severity.name}
                    value={severity.value}
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                            color: '#FFFFFF4D',
                          },
                        }}
                      />
                    }
                    label={<Typography color="white.100">{severity.name}</Typography>}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </StyledSeverityContainer>

        <Text fontSize="20px">Text</Text>
        <StyledQuill
          theme="snow"
          value={insight}
          onChange={(e) => handleInsightChange(e)}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link'],
              ['clean'],
              ['code-block'],
            ],
          }}
        />
        <StyledChartsWrapper>
          <WrapperCharts
            alertId={fetchedAlert.id}
            anomalyChainData={anomalyChainData}
            layout="row"
            chartData={formattedData}
            netflowData={formattedNetFlowData}
            totalflowData={formattedTotalFlowData}
            alertType={fetchedAlert.metadata?.type}
          />
        </StyledChartsWrapper>

        <AdminPanelUpdatesList
          updates={fetchedAlert.updates}
          alertID={fetchedAlert.id}
          anomalyChainData={anomalyChainData}
        />
        <ActionsSection isAnomaly={false} id={id} insight={insight} severity={severity} alertDetails={fetchedAlert} />
      </form>
    </StyledAlertDetailsContainer>
  );
};

export default AlertDetails;
