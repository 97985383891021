import { useEffect, useMemo, useState } from 'react';
import { flowDataChartOptions, flowOptions } from '@/constants/flows';
import { isOfType } from '@/helpers/typeGuards';
import { TFlowChart } from '@/types/flows';
import { ChartData } from '@/types/chartPoint';

interface IUseFlowChartsManagerProps {
  netflowData: ChartData;
  inflowData?: ChartData;
  outflowData?: ChartData;
  totalflowData: ChartData;
  hourlyNetflowData?: ChartData;
  hourlyInflowData?: ChartData;
  hourlyOutflowData?: ChartData;
  hourlyTotalflowData?: ChartData;
  alertType?: string | null;
}

export const useFlowChartsManager = ({
  netflowData,
  inflowData,
  outflowData,
  totalflowData,
  hourlyNetflowData,
  hourlyInflowData,
  hourlyOutflowData,
  hourlyTotalflowData,
  alertType,
}: IUseFlowChartsManagerProps) => {
  const filteredFlowDataChartOptions = useMemo(() => {
    const isNetflowAvailable = !!netflowData.length;
    const isInflowAvailable = !!inflowData?.length;
    const isOutflowAvailable = !!outflowData?.length;
    const isTotalflowAvailable = !!totalflowData.length;
    const iHourlyNetflowAvailable = !!hourlyNetflowData?.length;
    const isHourlyInflowAvailable = !!hourlyInflowData?.length;
    const isHourlyOutflowAvailable = !!hourlyOutflowData?.length;
    const isHourlyTotalflowAvailable = !!hourlyTotalflowData?.length;

    return flowDataChartOptions.filter((flowOption) => {
      return (
        (flowOption.value === 'netflow' && isNetflowAvailable) ||
        (flowOption.value === 'inflow' && isInflowAvailable) ||
        (flowOption.value === 'outflow' && isOutflowAvailable) ||
        (flowOption.value === 'totalflow' && isTotalflowAvailable) ||
        (flowOption.value === 'netflow-hourly' && iHourlyNetflowAvailable) ||
        (flowOption.value === 'inflow-hourly' && isHourlyInflowAvailable) ||
        (flowOption.value === 'outflow-hourly' && isHourlyOutflowAvailable) ||
        (flowOption.value === 'totalflow-hourly' && isHourlyTotalflowAvailable)
      );
    });
  }, [
    inflowData,
    netflowData,
    outflowData,
    totalflowData,
    hourlyNetflowData,
    hourlyInflowData,
    hourlyOutflowData,
    hourlyTotalflowData,
  ]);

  const isMoreThanOneFlowDataOption = useMemo(
    () => filteredFlowDataChartOptions.length > 1,
    [filteredFlowDataChartOptions],
  );

  const areFlowDataOptionsAvailable = useMemo(
    () => filteredFlowDataChartOptions.length !== 0,
    [filteredFlowDataChartOptions],
  );

  const getInitialSelection = () => {
    if (!areFlowDataOptionsAvailable) return undefined;

    return isOfType<TFlowChart>(filteredFlowDataChartOptions[0].value, flowOptions)
      ? filteredFlowDataChartOptions[0].value
      : undefined;
  };

  const [selectedFlowDataChart, setSelectedFlowDataChart] = useState<TFlowChart | undefined>(getInitialSelection());

  const handleSelectedFlowDataChange = (value: string) => {
    if (isOfType<TFlowChart>(value, flowOptions)) {
      setSelectedFlowDataChart(value);
    } else {
      console.error("The value doesn't below to the flow-data chart options.");
    }
  };

  const getFlowData = (selectedFlowDataChart: TFlowChart | undefined): ChartData => {
    switch (selectedFlowDataChart) {
      case 'netflow':
        return netflowData;
      case 'inflow':
        return inflowData || [];
      case 'outflow':
        return outflowData || [];
      case 'totalflow':
        return totalflowData;
      case 'netflow-hourly':
        return hourlyNetflowData || [];
      case 'inflow-hourly':
        return hourlyInflowData || [];
      case 'outflow-hourly':
        return hourlyOutflowData || [];
      case 'totalflow-hourly':
        return hourlyTotalflowData || [];
      default:
        return [];
    }
  };

  const getTitle = (flowType: TFlowChart | undefined) => {
    switch (flowType) {
      case 'netflow':
        return 'Net Asset Flows';
      case 'inflow':
        return 'Inflows';
      case 'outflow':
        return 'Outflows';
      case 'totalflow':
        return 'Total Flows';
      case 'netflow-hourly':
        return 'Net Asset Flows Hourly';
      case 'inflow-hourly':
        return 'Inflows Hourly';
      case 'outflow-hourly':
        return 'Outflows Hourly';
      case 'totalflow-hourly':
        return 'Total Flows Hourly';
      default:
        return '';
    }
  };

  const shouldRenderSelect = !!selectedFlowDataChart && isMoreThanOneFlowDataOption;
  const shouldRenderFlowsChart = !!selectedFlowDataChart && areFlowDataOptionsAvailable;
  const isHourlyChart =
    !!selectedFlowDataChart &&
    (selectedFlowDataChart === 'netflow-hourly' ||
      selectedFlowDataChart === 'inflow-hourly' ||
      selectedFlowDataChart === 'outflow-hourly' ||
      selectedFlowDataChart === 'totalflow-hourly');

  const generatedFlowData = useMemo(() => getFlowData(selectedFlowDataChart), [selectedFlowDataChart]);
  const generatedTitle = useMemo(() => getTitle(selectedFlowDataChart), [selectedFlowDataChart]);

  // 'netflow' is the first option by default, so there is no need to set selection
  useEffect(() => {
    if (alertType === 'totalflow') {
      const totalflowOption = filteredFlowDataChartOptions.find((option) => option.value === 'totalflow');

      if (totalflowOption) {
        setSelectedFlowDataChart('totalflow');
      }
    }
  }, []);

  return {
    shouldRenderSelect,
    filteredFlowDataChartOptions,
    handleSelectedFlowDataChange,
    selectedFlowDataChart,
    shouldRenderFlowsChart,
    generatedFlowData,
    generatedTitle,
    isHourlyChart,
  };
};
