import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserEntityDetails } from '@/api/userEntities/userEntities.hooks';
import Loader from '@/components/atoms/Loader/Loader';
import { EntityMonitoringView } from '@/views/EntityMonitoringView/EntityMonitoringView';
import { StyledLoaderContainer } from '@/views/EntityMonitoringView/EntityMonitoringView.styled';

export const EntityMonitoring = () => {
  const { entityName } = useParams();
  const { fetchedEntityDetails, isLoading, error } = useGetUserEntityDetails(entityName || '');

  if (isLoading)
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );

  if (error) {
    throw error;
  }

  if (!fetchedEntityDetails) {
    throw Error('Not found entity details data');
  }

  return <EntityMonitoringView fetchedData={fetchedEntityDetails} />;
};
