import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { StyledIconWrapper } from './IconWithHover.styled';
import { Link } from '@mui/material';

interface CommonProps {
  variant: 'link' | 'button';
  Icon: React.ElementType;
  tooltip: string;
}

interface LinkVariant {
  variant: 'link';
  onClick?: never;
  href: string;
}

interface ButtonVariant {
  variant: 'button';
  onClick: () => void;
  href?: never;
}

type TIconWithHoverProps = CommonProps & (LinkVariant | ButtonVariant);

export const IconWithHover = ({ variant, Icon, onClick, href, tooltip }: TIconWithHoverProps) => {
  if (variant === 'link') {
    return (
      <Tooltip title={tooltip}>
        <Link href={href}>
          <StyledIconWrapper>
            <Icon />
          </StyledIconWrapper>
        </Link>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip}>
      <StyledIconWrapper onClick={onClick}>
        <Icon />
      </StyledIconWrapper>
    </Tooltip>
  );
};
