import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import {
  IGetUserEntityDetailsResponse,
  IMonitoredEntity,
  IUserEntitiesApi,
} from '@/api/userEntities/userEntities.types';

export class UserEntitiesApi implements IUserEntitiesApi {
  private getBaseUrl(): string {
    return ApiEndpoints.USER_ENTITIES_BASE_URL;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getUserEntities(dashboardType: string | null) {
    const params = { entityTypes: dashboardType };

    return this.httpClient.get<IMonitoredEntity[]>(this.getBaseUrl(), { params });
  }

  getUserEntityDetails(entityId: string) {
    return this.httpClient.get<IGetUserEntityDetailsResponse>(`${this.getBaseUrl()}${entityId}/`);
  }
}
