import styled from '@emotion/styled';
import Button from '@/components/atoms/Button/Button';

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  --stroke-color: #fff;
`;
