import { useNavigate } from 'react-router-dom';
import { AppBar, Link, Toolbar, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import styled from '@emotion/styled';
import InfoIcon from '@/assets/icons/info.svg';
import ArrowLeft from '@/assets/icons/arrow-left.svg?react';
import SettingsIcon from '@/assets/icons/settings.svg?react';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import Button from '@/components/atoms/Button/Button';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { StyledButton } from '@/components/molecules/Headers/MonitoringHeader.styled';
import { ERoutes } from '@/types/routes';
import { useTracking } from '@/hooks/useTracking';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

interface IMonitoringHeaderProps {
  shouldDisplayBackButton?: boolean;
  shouldDisplaySettingsButton?: boolean;
}

const MonitoringHeader = ({
  shouldDisplayBackButton = false,
  shouldDisplaySettingsButton = false,
}: IMonitoringHeaderProps) => {
  const { shouldDisplayAlerts } = usePermissionsManager();
  const { trackEvent } = useTracking();
  const navigate = useNavigate();

  if (!shouldDisplayAlerts) {
    return (
      <StyledHeader position="static">
        <Toolbar>
          <Typography variant="h4">Risk Monitoring</Typography>
        </Toolbar>
      </StyledHeader>
    );
  }

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {shouldDisplayBackButton && (
            <Button
              onClick={() => navigate(-1)}
              skipTypography
              fitContentWidth
              disableRipple
              blockHoverBehavior
              sx={{ paddingInline: '5px' }}
            >
              <ArrowLeft />
            </Button>
          )}

          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="h4" style={{ flexGrow: 1, marginRight: '10px' }}>
              Risk Monitoring
            </Typography>
            <Tooltip
              arrow
              title={
                <>
                  <p>
                    We’re excited to introduce our new <strong>Risk Monitoring Tool</strong>, which provides real-time,
                    24/7 insights into your risk landscape. Building on our previous alerts system, this tool offers
                    enhanced customization and continuous monitoring to help you better manage risk across your
                    entities.
                  </p>
                  <p>
                    <strong>Key Features:</strong>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>24/7 Monitoring</strong>: Real-time, always-on tracking that captures significant
                        changes instantly, enabling faster responses.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Always-On Anomaly Detection</strong>: Anomalies are logged as soon as they happen,
                        keeping you up-to-date without relying on pre-scheduled updates.
                      </p>
                    </li>
                  </ul>
                  <p>
                    This new tool improves the granularity and usability of your risk data, offering deeper insights and
                    quicker visibility into potential risks.
                  </p>
                </>
              }
            >
              <img src={InfoIcon} alt="Info icon" />
            </Tooltip>
          </div>
        </div>

        {shouldDisplaySettingsButton && (
          <Tooltip arrow TransitionComponent={Zoom} placement="left" title="Monitoring settings">
            <Link
              href={ERoutes.MONITORING_SETTINGS}
              underline="none"
              onClick={() => trackEvent('Monitoring - click settings', {})}
            >
              <StyledButton variant="outlined" color="tertiary" skipTypography>
                <SettingsIcon />
                Customize
              </StyledButton>
            </Link>
          </Tooltip>
        )}
      </Toolbar>
    </StyledHeader>
  );
};

export default MonitoringHeader;
