import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import { EntityMonitoring } from '@/views/EntityMonitoringView/EntityMonitoring';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';

export const EntityMonitoringPage = () => {
  const { shouldDisplayAlerts } = usePermissionsManager();

  if (!shouldDisplayAlerts) {
    return <NoPermissionsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <EntityMonitoring />
    </ErrorBoundary>
  );
};
