import { useMemo } from 'react';
import { useAlertsFilters } from '@/providers/AlertsFiltersContextProvider/AlertsFiltersContext.hooks';
import { getTimeRangeStartDate } from '@/helpers/helpers';
import { IGetAlertsListRequest } from '@/api/alerts/alerts.types';
import { useGetAlertsList } from '@/api/alerts/alerts.hooks';

export const useFetchAlertsList = () => {
  const PAGE_SIZE = 10;

  const { page, exchangeFilter, timeRangeFilter, customStartDateFilter, customEndDateFilter, handlePage } =
    useAlertsFilters();

  const getStartDate = useMemo(() => {
    if (typeof timeRangeFilter === 'string') {
      return getTimeRangeStartDate(timeRangeFilter);
    }
  }, [timeRangeFilter]);

  const getEndDate = useMemo(() => new Date().toISOString(), []);

  const alertsListRequestPayload: Partial<IGetAlertsListRequest> = useMemo(() => {
    return {
      page: page,
      size: PAGE_SIZE,
      ...(exchangeFilter && exchangeFilter.length > 0 ? { exchange: exchangeFilter.join(',') } : {}),
      ...(timeRangeFilter && timeRangeFilter !== 'custom'
        ? {
            startDatetime: getStartDate,
            endDatetime: getEndDate,
          }
        : {}),
      ...(timeRangeFilter && timeRangeFilter === 'custom' && customStartDateFilter && customEndDateFilter
        ? {
            startDatetime: customStartDateFilter,
            endDatetime: customEndDateFilter,
          }
        : {}),
    };
  }, [customStartDateFilter, customEndDateFilter, exchangeFilter, getEndDate, getStartDate, page, timeRangeFilter]);

  const { alertsList, isLoading, error } = useGetAlertsList(alertsListRequestPayload);

  return {
    fetchedAlertsList: alertsList?.items || [],
    totalAlertsNumber: alertsList?.total || 0,
    isAlertsListExist: alertsList,
    isAlertsListLoading: isLoading,
    alertsListError: error,
    pageSize: PAGE_SIZE,
    page,
    handlePage,
  };
};
