import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import ArrowIcon from '@/assets/icons/arrow.svg?react';
import { TRiskIndicator } from '@/types/severityLevel';

export const StyledText = styled(Typography)<{ trend: TRiskIndicator }>`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: inherit;
  color: ${({ trend }) => {
    if (trend === 'decreasing') {
      return '#4EA72E';
    }

    if (trend === 'increasing') {
      return '#C00000';
    }

    if (trend === 'pending') {
      return '#ffffff80';
    }

    return '#FAC002';
  }};
`;

export const StyledArrowIcon = styled(ArrowIcon)<{ trend: Omit<TRiskIndicator, 'pending'> }>`
  --stroke-color: ${({ trend }) => {
    if (trend === 'decreasing') {
      return '#4EA72E';
    }

    if (trend === 'increasing') {
      return '#C00000';
    }

    return '#FAC002';
  }};

  transform: ${({ trend }) => {
    if (trend === 'decreasing') {
      return 'rotate(135deg)';
    }

    if (trend === 'increasing') {
      return 'rotate(45deg)';
    }

    return 'rotate(90deg)';
  }};
`;
