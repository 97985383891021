import styled from '@emotion/styled';
import { GridRowParams, GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { getCalendarDaysDifference } from '@/helpers/helpers';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { useTracking } from '@/hooks/useTracking';
import { getFullExchangeName, getKeyByLabel } from '@/helpers/exchangeNames';
import { isSeverityLevelKey } from '@/helpers/typeGuards';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import { StyledDataGrid, StyledRatedLink } from './StyledComponents';
import { ERoutes } from '@/types/routes';
import { IAlertDto } from '@/types/alert';

interface DataGridProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
  onRowSelected: (id: string) => void;
  data: IAlertDto[];
  customFooter?: () => React.ReactNode;
}

interface MyGridCellParams extends GridCellParams {
  row: IAlertDto;
  value: number;
}

const Container = styled.div`
  color: #fff;
  margin: 20px;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const CollapsibleDataGrid = ({ isCollapsed, toggleCollapse, onRowSelected, data }: DataGridProps) => {
  const { trackEvent } = useTracking();
  const { getExchangeLabelByDisplayName } = useEntitiesList();

  const handleRowClick = (params: GridRowParams) => {
    onRowSelected(params.row.id);
  };

  const getRowClassName = (params: GridRowParams) => {
    return `Mui-DataGrid-row ${params.row.id === '1' ? 'first-row' : ''}`;
  };

  const ExpandButtonHeader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#242427',
        borderRadius: '50%',
        padding: '3px',
      }}
    >
      <ChevronRight onClick={toggleCollapse} style={{ cursor: 'pointer' }} />
    </div>
  );

  const columnsWithExpand: any = !isCollapsed
    ? [
        {
          field: 'exchange',
          headerName: 'Company',
          width: 430,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <div style={{ display: 'flex', gap: '16px', marginLeft: '20px', alignItems: 'center' }}>
                <ExchangeLogo companyName={params.row.metadata.exchange} height="50px" />
                {params.row.entity?.displayName}
              </div>
            );
          },
        },
        {
          field: 'timestamp',
          headerName: 'Alert occurred',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <div>{getCalendarDaysDifference(params.row.timestamp)}</div>;
          },
        },
        {
          field: 'severity',
          headerName: 'Severity',
          width: 150,
          sortable: false,
          renderCell: (params: MyGridCellParams) => {
            const severity = params.value?.toString();

            if (isSeverityLevelKey(severity)) {
              return <AlertSeverityBarsIcon severity={severity} />;
            }
          },
        },
        {
          field: 'metadata',
          headerName: 'Entity type',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <div>{params.value.entityType?.toUpperCase()}</div>;
          },
        },
        {
          field: 'type',
          headerName: 'Variable',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <div>{mapMetadataType(params.row.metadata.type)}</div>;
          },
        },
        {
          field: 'isRated',
          headerName: 'Is rated',
          width: 200,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            const isRated = params.row.entity?.isRated;
            const labelToDisplay = params.row.entity?.isRated ? 'Rated' : 'Not rated';

            if (isRated) {
              const entity = getExchangeLabelByDisplayName(params.row.entity?.displayName);

              return (
                <StyledRatedLink
                  href={`${ERoutes.RATINGS}/${entity}`}
                  underline="none"
                  onClick={(e) => {
                    trackEvent('Alerts - click on rating link', {
                      entity,
                    });
                    e.stopPropagation();
                  }}
                >
                  {labelToDisplay}
                </StyledRatedLink>
              );
            }

            return <div>{labelToDisplay}</div>;
          },
        },
      ]
    : [
        {
          field: 'exchange',
          headerName: 'Company',
          width: 150,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <div style={{ display: 'flex', gap: '16px', marginLeft: '20px', alignItems: 'center' }}>
                <ExchangeLogo companyName={params.row.metadata.exchange} height="50px" />
                {params.row.entity?.displayName}
              </div>
            );
          },
        },
        {
          field: 'timestamp',
          headerName: 'Alert occurred',
          width: 100,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            return <div>{getCalendarDaysDifference(params.row.timestamp)}</div>;
          },
        },
        {
          field: 'expand',
          headerName: 'Expand',
          width: 100,

          sortable: false,
          headerClassName: 'custom-header',
          renderHeader: () => (isCollapsed ? <ExpandButtonHeader /> : null),
        },
      ];

  const getRowId = (row: any) => row.id;

  return (
    <>
      {data?.length > 0 ? (
        <StyledDataGrid
          style={{ color: '#fff' }}
          rows={data}
          getRowId={getRowId}
          columns={columnsWithExpand}
          onRowClick={handleRowClick}
          disableColumnMenu
          isCollapsed={isCollapsed}
          getRowClassName={getRowClassName}
          hideFooter
        />
      ) : (
        <Container>No alerts matching the filters</Container>
      )}
    </>
  );
};

export default CollapsibleDataGrid;
