import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useCreateEntityForm } from '@/views/AdminPanelView/components/Entities/AdminPanelEntitiesList/components/CreateNewEntityForm/utils/useCreateEntityForm';
import { ControlledBaseTextField } from '@/components/atoms/BaseTextField/BaseTextField';
import { ControlledCustomSwitch } from '@/components/atoms/CustomSwitch/ControlledCustomSwitch';
import Text from '@/components/atoms/Text/Text';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import {
  StyledForm,
  StyledInputWrapper,
  StyledWrapperButton,
  StyledInputsContainer,
  StyledColumnContainer,
  StyledQuill,
} from './CreateNewEntityForm.styled';
import { ICreateNewEntityModalProps } from '../CreateNewEntityModal/CreateNewEntityModal';
import 'react-quill/dist/quill.snow.css';

export const CreateNewEntityForm = ({
  closeModal,
  refetchListOfEntities,
}: Pick<ICreateNewEntityModalProps, 'closeModal' | 'refetchListOfEntities'>) => {
  const { createEntityMethods, handleCreateEntityFormSubmit } = useCreateEntityForm({
    closeModal,
    refetchListOfEntities,
  });
  return (
    <FormProvider {...createEntityMethods}>
      <StyledForm onSubmit={handleCreateEntityFormSubmit}>
        <StyledInputsContainer>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Display name" inputName="displayName" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Agio id" inputName="agioId" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Arkham id" inputName="arkhamId" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Coingecko id" inputName="coingeckoId" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Crypto compare id"
              inputName="cryptoCompareId"
              formMethods={createEntityMethods}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Crypto compare name"
              inputName="cryptoCompareName"
              formMethods={createEntityMethods}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Glassnode id" inputName="glassnodeId" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Type" inputName="type" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Subtype" inputName="subtype" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Status" inputName="status" formMethods={createEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Similarweb domain"
              inputName="similarwebDomain"
              formMethods={createEntityMethods}
              helperText="Separate domains using semicolon and space: '; '"
              helperTextBackgroundColor="#242428"
            />
          </StyledInputWrapper>
        </StyledInputsContainer>

        <StyledInputsContainer>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Linkedin url" inputName="linkedinQuery" formMethods={createEntityMethods} />
          </StyledInputWrapper>
        </StyledInputsContainer>

        <StyledColumnContainer>
          <Text fontSize="20px">Custom notes</Text>
          <Controller
            render={({ field, ...formProps }) => {
              return (
                <StyledQuill
                  theme="snow"
                  placeholder="Provide custom notes."
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                  onBlur={field.onBlur}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link'],
                      ['code-block'],
                    ],
                  }}
                  {...formProps}
                />
              );
            }}
            name="analystNotes"
            control={createEntityMethods.control}
          />
        </StyledColumnContainer>

        <StyledInputsContainer>
          <ControlledCustomSwitch inputName="isRated" formMethods={createEntityMethods} label="Is rated entity" />
          <ControlledCustomSwitch
            inputName="visibleToCustomer"
            formMethods={createEntityMethods}
            label="Should be visible for customers"
          />
        </StyledInputsContainer>

        <StyledWrapperButton>
          <AdminButton type="submit">Create</AdminButton>
        </StyledWrapperButton>
      </StyledForm>
    </FormProvider>
  );
};
