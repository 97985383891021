import {
  FieldArrayWithId,
  FieldError,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFormReturn,
} from 'react-hook-form';
import { BaseSyntheticEvent } from 'react';
import dayjs from 'dayjs';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { IOption } from '@/types/select';
import { IDatePickerRange } from '@/types/datePicker';

export interface IRiskSimulatorContext {
  riskSimulatorMethods: UseFormReturn<IRiskSimulatorForm>;
  handleRiskSimulatorFormSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  resultOfRiskSimulation: IResultOfRiskSimulation | null;
  isSimulationLoading: boolean;
  parametersOfDisplayedSimulation: IParametersOfSimulation | null;

  ratings: IStatisticalRatingEntity[];

  datePickerManager: IDatePickerManager;
  watchedRowsValues: IRow[];
  totalExposure: number;
  rowsManager: IRowsManager;
  autocompleteManager: IAutocompleteManager;
  handleResetForm: () => void;
}

export interface IRow {
  entity: string;
  pd: number;
  recovery: number;
  exposure: number;
  percentExposure: number;
  isCustom: boolean;
}

export type TContagionLevel = 'none' | 'low' | 'medium' | 'high' | 'very-high';

export interface IContagionLevel {
  name: string;
  value: TContagionLevel;
  correlation: number;
}

export interface IRiskSimulatorForm {
  rows: IRow[];
  contagionLevel: IContagionLevel;
  pdSelectedDate: string;
}

export type TTransformedDataFrequency = [string, number];

export interface IParametersOfSimulation {
  correlation: number;
  contagionLevel: string;
  numberOfExchanges: number;
  rows: IRow[];
  pdSelectedDate: string;
}

export interface IResultOfRiskSimulation {
  totalExposure: string;
  expectedLossUsd: string;
  unexpectedLossUsd: string;
  expectedLossPct: string;
  unexpectedLossPct: string;
  zeroDefaults: string;
  probExceedingLoss?: string;
  creditVar?: string;
  creditVar950Pct?: string;
  creditVar990Pct?: string;
  creditVar999Pct?: string;
  creditVar950Usd?: string;
  creditVar990Usd?: string;
  creditVar999Usd?: string;
  defaultsFrequency?: TTransformedDataFrequency[];
  lossFrequency?: TTransformedDataFrequency[];
}

export enum EFileKeys {
  ENTITY = 'Exchange ID',
  PD = '1-YR PD value',
  RECOVERY = 'Recovery (%)',
  EXPOSURE = 'Exposure (USD)',
}

export interface IDatePickerManager {
  availableDatesRange: IDatePickerRange;
  selectedDate: dayjs.Dayjs;
  handleDatePickerChange: (newValue: unknown) => void;
  formattedSelectedDate: string;
}

export interface IRowsManager {
  rowFields: FieldArrayWithId<IRiskSimulatorForm, 'rows', 'id'>[];
  replaceRows: UseFieldArrayReplace<IRiskSimulatorForm, 'rows'>;
  removeRow: UseFieldArrayRemove;
  entityError: FieldError | undefined;
  handleAddRow: () => void;
  handleAddCustomRow: () => void;
}

export interface IAutocompleteManager {
  filteredOptions: IOption[];
  handleSelectedOptionChange: (value: unknown, idx: number) => void;
  removeDeletedOptionFromSelections: (idx: number) => void;
  isMultipleEntities: boolean;
  clearSelectedOptions: () => void;
}
