import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { getKeyByLabel } from '@/helpers/exchangeNames';
import { ELocalStorage } from '@/types/storage';
import { IRow, EFileKeys } from '@/views/RiskSimulatorPortfolioView/utils/types';
import { IStatisticalRatingEntity } from '@/types/ratings';

type ExcelRows = { [key in EFileKeys]: string };

interface IUseCsvRiskSimulatorManagerProps {
  ratings: IStatisticalRatingEntity[];
  exchangeList: string[];
  timestamp: string;
  replaceRows: (value: IRow[]) => void;
}

export const useCsvRiskSimulatorManager = ({
  ratings,
  exchangeList,
  timestamp,
  replaceRows,
}: IUseCsvRiskSimulatorManagerProps) => {
  const [excelRows, setExcelRows] = useState<ExcelRows[]>([]);

  const parseCurrency = (currencyStr: string): number => {
    const numericPart = currencyStr.replace(/[^0-9.-]+/g, '');
    return parseFloat(numericPart);
  };

  useEffect(() => {
    const csvOutput = localStorage.getItem(ELocalStorage.PORTFOLIO_CSV_DATA);
    if (csvOutput) {
      const parsedCSV = Papa.parse(csvOutput, { header: true });
      const rows = parsedCSV.data;
      setExcelRows(rows as ExcelRows[]);
      localStorage.removeItem(ELocalStorage.PORTFOLIO_CSV_DATA);
    }
  }, []);

  useEffect(() => {
    if (excelRows.length > 0) {
      const clearedExcelRows = [...excelRows].filter((obj) => {
        const values = Object.values(obj);
        return values.some((val) => val);
      });

      const mappedRows: IRow[] = clearedExcelRows.map((obj) => {
        const entity = getKeyByLabel(obj[EFileKeys.ENTITY])
          ? getKeyByLabel(obj[EFileKeys.ENTITY])
          : obj[EFileKeys.ENTITY];

        const pd = obj[EFileKeys.PD].replace(/,/g, '.').replace('%', '');
        const recovery = obj[EFileKeys.RECOVERY].replace(/,/g, '.').replace('%', '');
        const exposure = parseCurrency(obj[EFileKeys.EXPOSURE]);

        const isExistingExchange = exchangeList.find((exchange) => exchange === entity);

        if (isExistingExchange) {
          const rating = ratings.find((rating) => rating.entity === entity);

          if (rating) {
            const findPD = rating.data.find((d) => timestamp === d.timestamp.split('T')[0]);

            return {
              entity,
              pd: findPD?.value || +pd,
              recovery: +recovery,
              exposure: +exposure,
              isCustom: false,
              percentExposure: 0,
            };
          }
        }

        return {
          entity,
          pd: +pd,
          recovery: +recovery,
          exposure: +exposure,
          isCustom: true,
          percentExposure: 0,
        };
      });

      replaceRows(mappedRows);
    }
  }, [excelRows]);
};
