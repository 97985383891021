import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from '@mui/material';

export const StyledDataGrid = styled(DataGrid)<{ isCollapsed: boolean }>`
  border-color: transparent;
  border-top: #2e2e31;
  flex: none;
  height: auto;

  & .first-row {
    background-color: transparent !important;
  }

  .MuiDataGrid-columnHeader:focus-within {
    outline: none;
    border: none;
  }

  .MuiDataGrid-columnHeaderTitle {
    opacity: 0.5;
  }

  .MuiTablePagination-displayedRows {
    color: white;
  }

  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-footerContainer {
    border-bottom: 1px solid #2e2e31;
  }

  .MuiDataGrid-columnHeader:focus {
    border: transparent;
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    border: none;
    cursor: default;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-row.Mui-selected,
  .MuiDataGrid-row.Mui-selected.Mui-hovered,
  .MuiDataGrid-row:hover {
    background: #222;
    cursor: pointer;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
  //____________________________
  // styles to keep pagination close to the table once the view is not collapsed
  .MuiDataGrid-main {
    flex-grow: 0;
  }

  .MuiDataGrid-virtualScroller {
    height: auto;
  }

  .MuiDataGrid-virtualScrollerContent {
    min-height: auto !important;
  }
`;

export const StyledRatedLink = styled(Link)`
  color: #fff;
  transition: color 0.3s;

  &:hover {
    color: #4859f4;
  }
`;
