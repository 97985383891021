import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 80px 50px;
`;

export const StyledLogo = styled.img`
  width: 218px;
  height: 70px;
  filter: blur(1px);
  opacity: 0.1;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 0 10px;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 500;
  font-size: 42px;
  line-height: 120%;
  text-align: center;
`;

export const StyledInfo = styled.p`
  margin: 0;
  width: 100%;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #ffffff;
  text-align: justify;
`;

export const StyledCloseButton = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  width: 120px;
  height: 42px;
  background: #ffffff;
  cursor: pointer;
  transition: opacity 0.3s, color 0.3s;
  font-family: 'General Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #463679;

  :hover {
    opacity: 0.8;
    color: #e5b302;
  }
`;
