import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import ActionsButtons from '@/components/molecules/ActionsButtons/ActionsButtons';
import ActionAnomalyButtons from '@/components/molecules/ActionAnomalyButtons/ActionAnomalyButtons';
import { useCreateAlert, useDeleteAlert, useEditAlert } from '@/api/adminAlerts/adminAlerts.hooks';
import { SeverityLevelKey } from '@/types/severityLevel';
import { EAdminPaths, ERoutes } from '@/types/routes';

const ActionsContainer = styled.div`
  display: column;
  align-items: center;
  margin-top: 20px;
  border-top: solid 1px #fff;
`;

const ActionsSection = ({
  isAnomaly,
  id,
  insight,
  alertDetails,
  severity,
}: {
  isAnomaly: boolean;
  id: string | undefined;
  insight: string;
  alertDetails: any;
  severity: SeverityLevelKey;
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { editAlert } = useEditAlert();
  const { deleteAlert } = useDeleteAlert();
  const { createAlert } = useCreateAlert();

  const handleAlertSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const payload = {
      alertId: id || '',
      ...(alertDetails.insight === insight ? {} : { insight: insight }),
      ...(alertDetails.severity === severity ? {} : { severity: severity }),
    };

    editAlert(payload, {
      onSuccess: () => {
        navigate(`${ERoutes.ALERTS}/${id}`);
      },
      onError: (error) => {
        console.error('Error updating alert:', error);
        setErrorMessage(error.message);
      },
    });
  };

  const handleAlertDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    deleteAlert(
      { alertId: id || '' },
      {
        onSuccess: () => {
          navigate(`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}`);
        },
        onError: (error) => {
          console.error('Error deleting alert:', error);
          setErrorMessage(error.message);
        },
      },
    );
  };

  const handleAnomalySubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const payload = {
      anomalyId: id || '',
      ...(alertDetails.insight === insight || insight === '' ? {} : { insight: insight }),
      ...(alertDetails.severity === severity ? {} : { severity: severity }),
    };

    createAlert(payload, {
      onSuccess: (response) => {
        navigate(`${ERoutes.ALERTS}/${response.id}`);
      },
      onError: (error) => {
        console.error('Error creating alert:', error);
        setErrorMessage(error.message);
      },
    });
  };

  const handleCancel = () => navigate(`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}`);

  return (
    <ActionsContainer>
      {isAnomaly ? (
        <ActionAnomalyButtons
          handleSave={handleAnomalySubmit}
          handleCancel={handleCancel}
          isReviewed={alertDetails.reviewed}
        />
      ) : (
        <ActionsButtons
          handleSave={handleAlertSubmit}
          handleDelete={handleAlertDelete}
          handleCancel={handleCancel}
          isPublished={alertDetails.reviewed}
        />
      )}

      {errorMessage && <Typography color="red">{errorMessage}</Typography>}
    </ActionsContainer>
  );
};

export default ActionsSection;
