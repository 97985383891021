import styled from '@emotion/styled';
import { Link } from '@mui/material';

export const DASHBOARD_LIST_GAP = 16;

export const StyledLink = styled(Link)`
  flex: 0 0 calc(33.33% - ${DASHBOARD_LIST_GAP}px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #26284b;
  opacity: 0.7;
  transition: opacity 0.3s, box-shadow 0.3s;

  &:hover {
    opacity: 1;
    box-shadow: 0 0 3px 3px #fff;
  }
`;

export const StyledImg = styled.img<{ height: string }>`
  height: ${({ height }) => height};
`;

export const StyledTopPartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
