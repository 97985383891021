import { z, ZodType } from 'zod';
import { ANOMALIES_EMAIL_NOTIFICATIONS_OPTIONS } from '@/views/MonitoringSettingsView/utils/constants';
import { ISpecificOption } from '@/types/select';

export interface IMonitoringForm {
  entities: string[];
  sortedEntities: string[];
}

export const MonitoringSchema: ZodType<IMonitoringForm> = z.object({
  entities: z.string().array().min(1, { message: 'Select at least 1 entity to monitor.' }),
  sortedEntities: z.string().array(),
});

export type AnomaliesEmailNotificationsOptionKey = (typeof ANOMALIES_EMAIL_NOTIFICATIONS_OPTIONS)[number];

export type TAnomaliesEmailNotificationsOption = ISpecificOption<AnomaliesEmailNotificationsOptionKey>;

export interface IAnomaliesEmailNotificationsForm {
  notificationsMode: AnomaliesEmailNotificationsOptionKey;
  selectedEntities: string[];
}

export const AnomaliesEmailNotificationsSchema: ZodType<IAnomaliesEmailNotificationsForm> = z
  .object({
    notificationsMode: z.enum(ANOMALIES_EMAIL_NOTIFICATIONS_OPTIONS),
    selectedEntities: z.string().array(),
  })
  .superRefine((data, ctx) => {
    if (data.notificationsMode === 'select' && !data.selectedEntities.length) {
      ctx.addIssue({
        path: ['selectedEntities'],
        message: 'At least one entity must be selected.',
        code: z.ZodIssueCode.custom,
      });
    }
  });
