import styled from '@emotion/styled';
import { Link } from '@mui/material';
import Button from '@/components/atoms/Button/Button';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 22px 24px;
  width: 100%;
`;

export const StyledLoaderContainer = styled.div`
  margin: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  --stroke-color: #fff;
`;

export const StyledLink = styled(Link)`
  align-self: center;
  width: fit-content;
`;
