import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, FormProvider } from 'react-hook-form';
import Text from '@/components/atoms/Text/Text';
import { ControlledBaseTextField } from '@/components/atoms/BaseTextField/BaseTextField';
import { ControlledCustomSwitch } from '@/components/atoms/CustomSwitch/ControlledCustomSwitch';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import { useEditEntityForm } from '@/views/AdminPanelView/components/Entities/AdminPanelEntityDetails/components/EditEntityDetailsForm/utils/useEditEntityForm';
import { IEntityDto } from '@/types/entities';
import {
  StyledForm,
  StyledInputWrapper,
  StyledButtonsContainer,
  StyledWrapperButton,
  StyledInputsContainer,
  StyledQuill,
  StyledColumnContainer,
} from './EditEntityDetailsForm.styled';
import { EAdminPaths, ERoutes } from '@/types/routes';
import 'react-quill/dist/quill.snow.css';

export interface IEditEntityDetailsFormProps {
  entity: IEntityDto;
}

export const EditEntityDetailsForm = ({ entity }: IEditEntityDetailsFormProps) => {
  const navigate = useNavigate();

  const { editEntityMethods, handleEditEntityFormSubmit } = useEditEntityForm({ entity });

  const handleCancel = () => {
    navigate(`${ERoutes.ADMIN}/${EAdminPaths.ENTITIES}`);
  };

  return (
    <FormProvider {...editEntityMethods}>
      <StyledForm onSubmit={handleEditEntityFormSubmit}>
        <StyledInputsContainer>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Display name" inputName="displayName" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Agio id" inputName="agioId" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Arkham id" inputName="arkhamId" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Coingecko id" inputName="coingeckoId" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Crypto compare id"
              inputName="cryptoCompareId"
              formMethods={editEntityMethods}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Crypto compare name"
              inputName="cryptoCompareName"
              formMethods={editEntityMethods}
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Glassnode id" inputName="glassnodeId" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Type" inputName="type" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Subtype" inputName="subtype" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Status" inputName="status" formMethods={editEntityMethods} />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <ControlledBaseTextField
              label="Similarweb domain"
              inputName="similarwebDomain"
              formMethods={editEntityMethods}
              helperText="Separate domains using semicolon and space: '; '"
            />
          </StyledInputWrapper>
        </StyledInputsContainer>

        <StyledInputsContainer>
          <StyledInputWrapper>
            <ControlledBaseTextField label="Linkedin url" inputName="linkedinQuery" formMethods={editEntityMethods} />
          </StyledInputWrapper>
        </StyledInputsContainer>

        <StyledColumnContainer>
          <Text fontSize="20px">Custom notes</Text>
          <Controller
            render={({ field, ...formProps }) => {
              return (
                <StyledQuill
                  theme="snow"
                  placeholder="Provide custom notes."
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                  onBlur={field.onBlur}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link'],
                      ['code-block'],
                    ],
                  }}
                  {...formProps}
                />
              );
            }}
            name="analystNotes"
            control={editEntityMethods.control}
          />
        </StyledColumnContainer>

        <StyledInputsContainer>
          <ControlledCustomSwitch inputName="isRated" formMethods={editEntityMethods} label="Is rated entity" />
          <ControlledCustomSwitch
            inputName="visibleToCustomer"
            formMethods={editEntityMethods}
            label="Should be visible for customers"
          />
        </StyledInputsContainer>

        <StyledButtonsContainer>
          <StyledWrapperButton>
            <AdminButton type="submit">Save Changes</AdminButton>
          </StyledWrapperButton>
          <StyledWrapperButton>
            <AdminButton type="button" onClick={handleCancel}>
              Cancel
            </AdminButton>
          </StyledWrapperButton>
        </StyledButtonsContainer>
      </StyledForm>
    </FormProvider>
  );
};
