import { RiskSimulatorPortfolioView } from '@/views/RiskSimulatorPortfolioView/RiskSimulatorPortfolioView';
import Loader from '@/components/atoms/Loader/Loader';
import { StyledContainer } from '@/views/RiskSimulatorPortfolioView/RiskSimulatorPortfolio.styled';
import { useRatings } from '@/providers/RatingsListContextProvider/RatingsListContext.hooks';

export const RiskSimulatorPortfolio = () => {
  const { ratings, isLoadingRatings, ratingsError } = useRatings();

  if (isLoadingRatings)
    return (
      <StyledContainer>
        <Loader />
      </StyledContainer>
    );

  if (ratingsError) {
    throw ratingsError;
  }

  if (ratings && !ratings.data.length) {
    throw Error('Not found ratings data');
  }

  return <RiskSimulatorPortfolioView fetchedRatings={ratings.data} />;
};
