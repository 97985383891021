import { useRef } from 'react';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { NumberOfDefaultsSection } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/components/NumberOfDefaultsChart/NumberOfDefaultsSection';
import { NonZeroLossesSection } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/components/NonZeroLossesChart/NonZeroLossesSection';
import { CorrelationsSection } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/components/CorrelationsChart/CorrelationsSection';
import { StyledWrapper } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/SimulatedCharts.styled';

export const SimulatedCharts = () => {
  const { resultOfRiskSimulation, parametersOfDisplayedSimulation } = useRiskSimulator();
  const { correlation, rows } = parametersOfDisplayedSimulation;

  const numberOfDefaultsRef = useRef<HighchartsReactRefObject>(null);
  const nonZeroLossesRef = useRef<HighchartsReactRefObject>(null);
  const correlationRef = useRef<HighchartsReactRefObject>(null);

  const exportCSV = () => {
    if (numberOfDefaultsRef.current !== null && numberOfDefaultsRef.current.chart) {
      numberOfDefaultsRef.current.chart.downloadCSV();
    }

    if (nonZeroLossesRef.current !== null && nonZeroLossesRef.current.chart) {
      nonZeroLossesRef.current.chart.downloadCSV();
    }

    if (correlationRef.current !== null && correlationRef.current.chart) {
      correlationRef.current.chart.downloadCSV();
    }
  };

  const exportPDF = () => {
    if (numberOfDefaultsRef.current !== null && numberOfDefaultsRef.current.chart) {
      numberOfDefaultsRef.current.chart.exportChartLocal(
        {
          type: 'application/pdf',
          filename: 'NUMBER OF DEFAULTS',
        },
        {},
      );
    }

    if (nonZeroLossesRef.current !== null && nonZeroLossesRef.current.chart) {
      nonZeroLossesRef.current.chart.exportChartLocal(
        {
          type: 'application/pdf',
          filename: 'NON-ZERO LOSSES',
        },
        {},
      );
    }

    if (correlationRef.current !== null && correlationRef.current.chart) {
      correlationRef.current.chart.exportChartLocal(
        {
          type: 'application/pdf',
          filename: 'CORRELATION MULTIPLIER',
        },
        {},
      );
    }
  };

  const handleDownloadFullDistribution = () => {
    exportCSV();
    exportPDF();
  };

  return (
    <StyledWrapper>
      {resultOfRiskSimulation.defaultsFrequency && (
        <NumberOfDefaultsSection
          defaultsFrequency={resultOfRiskSimulation.defaultsFrequency}
          numberOfDefaultsRef={numberOfDefaultsRef}
          handleDownloadFullDistribution={handleDownloadFullDistribution}
        />
      )}

      {resultOfRiskSimulation.lossFrequency && (
        <NonZeroLossesSection
          lossFrequency={resultOfRiskSimulation.lossFrequency}
          nonZeroLossesRef={nonZeroLossesRef}
        />
      )}

      {correlation && rows && (
        <CorrelationsSection correlation={correlation} rows={rows} correlationRef={correlationRef} />
      )}
    </StyledWrapper>
  );
};
