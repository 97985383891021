import { ChartData, ChartPoint } from '@/types/chartPoint';
import { adjustSeverityChartPoint } from '@/helpers/severityLevelMethods';
import { TSeverity } from '@/types/severityLevel';

interface DataItem {
  timestamp: string;
  value: number | null;
}

export const mapChainData = (data: Array<DataItem>): ChartData => {
  let mapped: ChartData = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: item.value,
      };
    });

  return mapped;
};

interface SeverityDataItem {
  timestamp: string;
  value: TSeverity;
}

export const mapSeverityChartData = (data: Array<SeverityDataItem>): ChartData => {
  let mapped: ChartPoint[] = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: adjustSeverityChartPoint(item.value),
      };
    });

  return mapped;
};
