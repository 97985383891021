import styled from '@emotion/styled';
import { DASHBOARD_LIST_GAP } from '@/views/MonitoringView/components/DashboardCard/DashboardCard.styled';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 22px 24px;
  width: 100%;
  min-width: 500px;
`;

export const StyledCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${DASHBOARD_LIST_GAP}px;
  width: 100%;
  max-width: 1000px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
