import styled from '@emotion/styled';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background: #242428;
  padding: 40px;
  border-radius: 8px;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 90%;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  gap: 10px;
`;

export const StyledChartsWrapper = styled.div`
  padding: 34px;
  border: 1px solid #2e2e31;
  border-radius: 4px;
  background-color: #101014;
`;
