import { RiskSimulatorProvider } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { Simulator } from '@/views/RiskSimulatorPortfolioView/components/Simulator/Simulator';
import { ResultOfRiskSimulation } from './components/ResultOfRiskSimulation/ResultOfRiskSimulation';
import { StyledContainer, StyledSeparator } from '@/views/RiskSimulatorPortfolioView/RiskSimulatorPortfolioView.styled';
import { IStatisticalRatingEntity } from '@/types/ratings';

export interface IRiskSimulatorPortfolioViewProps {
  fetchedRatings: IStatisticalRatingEntity[];
}

export const RiskSimulatorPortfolioView = ({ fetchedRatings }: IRiskSimulatorPortfolioViewProps) => {
  return (
    <StyledContainer>
      <RiskSimulatorProvider fetchedRatings={fetchedRatings}>
        <Simulator />

        <StyledSeparator />

        <ResultOfRiskSimulation />
      </RiskSimulatorProvider>
    </StyledContainer>
  );
};
