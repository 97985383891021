import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useMonitoringForm } from '@/views/MonitoringSettingsView/utils/useMonitoringForm';
import Button from '@/components/atoms/Button/Button';
import { EntitiesMonitoringForm } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/EntitiesMonitoringForm';
import {
  StyledButtonContainer,
  StyledForm,
} from '@/views/MonitoringSettingsView/components/EntitiesMonitoringSection/EntitiesMonitoringSection.styled';

export const EntitiesMonitoringSection = () => {
  const {
    monitoringMethods,
    handleMonitoringFormSubmit,
    isCreateMonitoringSettingsLoading,
    isGetMonitoringSettingsLoading,
  } = useMonitoringForm();
  return (
    <FormProvider {...monitoringMethods}>
      <StyledForm onSubmit={handleMonitoringFormSubmit}>
        <EntitiesMonitoringForm
          formMethods={monitoringMethods}
          isGetMonitoringSettingsLoading={isGetMonitoringSettingsLoading}
        />
        <StyledButtonContainer>
          <Button
            type="submit"
            variant="contained"
            fitContentWidth
            sx={{ minWidth: '142px' }}
            disabled={isCreateMonitoringSettingsLoading}
          >
            Save
          </Button>
        </StyledButtonContainer>
      </StyledForm>
    </FormProvider>
  );
};
