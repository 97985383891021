export const USER_KEY = 'user';
export const USER_ENTITIES_KEY = 'user_entities';
export const ADMIN_USER_KEY = 'admin_user';
export const ADMIN_ALERTS_KEY = 'admin_alerts';
export const ADMIN_ENTITY_IDS_KEY = 'admin_entity_ids';
export const ADMIN_RATINGS_KEY = 'admin_ratings';
export const ADMIN_RISKS_KEY = 'admin_risks';
export const USER_LIST_KEY = 'user_list';
export const AUTH = 'authentication';
export const ALERTS_KEY = 'alerts';
export const ADMIN_ANOMALIES_KEY = 'admin_anomalies';
export const CALCULATORS_KEY = 'calculators';
export const RISKS_KEY = 'risks';
export const RATINGS_KEY = 'ratings';
export const DOCUMENTATION_KEY = 'documentation';
