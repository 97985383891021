import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { defaultAnomaliesEmailNotificationsFormValues } from '@/views/MonitoringSettingsView/utils/constants';
import { useEditUser, useGetUserById } from '@/api/user/user.hooks';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { useTracking } from '@/hooks/useTracking';
import { ERoutes } from '@/types/routes';
import { ELocalStorage } from '@/types/storage';
import {
  AnomaliesEmailNotificationsSchema,
  IAnomaliesEmailNotificationsForm,
} from '@/views/MonitoringSettingsView/utils/types';

export const useAnomaliesEmailNotificationsForm = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { exchanges, getExchangeLabelByDisplayName, getDisplayNameByExchangeLabel } = useEntitiesList();
  const user = localStorage.getItem(ELocalStorage.USER);
  const userId: string = user && JSON.parse(user).userId;

  const anomaliesEmailNotificationsMethods = useForm<IAnomaliesEmailNotificationsForm>({
    defaultValues: defaultAnomaliesEmailNotificationsFormValues,
    resolver: zodResolver(AnomaliesEmailNotificationsSchema),
    mode: 'all',
  });

  const { editUser, isLoading } = useEditUser();

  const { handleSubmit, reset } = anomaliesEmailNotificationsMethods;

  const generateEntitiesSettings = (data: IAnomaliesEmailNotificationsForm) => {
    switch (data.notificationsMode) {
      case 'all':
        return null;
      case 'none':
        return [];
      case 'select':
        return data.selectedEntities.map((entity) => getExchangeLabelByDisplayName(entity));
      default:
        return null;
    }
  };

  const onAnomaliesEmailNotificationsSubmit = useCallback(
    async (data: IAnomaliesEmailNotificationsForm) => {
      if (!userId) return;

      const entitiesSettings = generateEntitiesSettings(data);

      const payload = {
        id: userId,
        notificationSettings: { entities: entitiesSettings },
      };

      trackEvent('Monitoring settings/email notifications - save', {
        entities: payload,
      });

      editUser(payload, {
        onSuccess: () => {
          navigate(ERoutes.MONITORING);
        },
        onError: (error) => {
          console.error('Monitoring settings setup failed: ', error.message);
        },
      });
    },
    [exchanges, userId],
  );

  const handleAnomaliesEmailNotificationsFormSubmit = handleSubmit(onAnomaliesEmailNotificationsSubmit);

  const { fetchedUser, isSuccess, isLoading: isGetEmailSettingsLoading } = useGetUserById({ id: userId });

  useEffect(() => {
    if (!fetchedUser || !fetchedUser.notificationSettings || !isSuccess) return;

    if (fetchedUser.notificationSettings.entities === null) {
      // this is the default setup so there is no need to reset state
      return;
    }

    if (fetchedUser.notificationSettings.entities.length === 0) {
      const formState: IAnomaliesEmailNotificationsForm = {
        notificationsMode: 'none',
        selectedEntities: defaultAnomaliesEmailNotificationsFormValues.selectedEntities,
      };

      return reset(formState);
    }

    const mappedEntities = fetchedUser.notificationSettings.entities.map((e) => getDisplayNameByExchangeLabel(e));
    const formState: IAnomaliesEmailNotificationsForm = {
      notificationsMode: 'select',
      selectedEntities: mappedEntities,
    };

    reset(formState);
  }, [exchanges, fetchedUser, getDisplayNameByExchangeLabel, isSuccess]);

  return {
    anomaliesEmailNotificationsMethods,
    handleAnomaliesEmailNotificationsFormSubmit,
    isEditEmailSettingsLoading: isLoading,
    isGetEmailSettingsLoading,
  };
};
