import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { isObjectWithKey } from '@/helpers/typeGuards';
import { getFullExchangeTypeName } from '@/helpers/exchangeNames';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import {
  GroupHeader,
  GroupItems,
  StyledAutocomplete,
  StyledPaper,
} from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/components/EntitiesToEmailNotification/EntitiesToEmailNotification.styled';
import { IEntitiesEmailNotificationsFormProps } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/EntitiesEmailNotificationsForm';

import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const EntitiesToEmailNotification = ({
  formMethods,
}: Pick<IEntitiesEmailNotificationsFormProps, 'formMethods'>) => {
  const { exchanges, listOfEntityTypes } = useEntitiesList();

  const listOfEntityTypesToDisplay = useMemo(() => {
    return listOfEntityTypes.filter(
      (type) => type !== 'defi' && type !== 'gambling' && type !== 'misc' && type !== 'infrastructure',
    );
  }, [listOfEntityTypes]);

  const sortedExchangesByType = useMemo(() => {
    if (!exchanges.length || !listOfEntityTypesToDisplay.length) return [];

    const sortedBucket: IExchange[] = [];

    listOfEntityTypesToDisplay.forEach((type, i) => {
      exchanges.forEach((exchange) => {
        if (exchange.type === type) {
          sortedBucket.push(exchange);
        }
      });
    });

    return sortedBucket;
  }, [listOfEntityTypesToDisplay, exchanges]);

  return (
    <Controller
      render={({ field, formState: { errors, isValid } }) => {
        return (
          <StyledAutocomplete
            multiple
            id="entities-list-to-notificate"
            disableCloseOnSelect
            disablePortal
            options={sortedExchangesByType}
            groupBy={(option) => {
              if (isObjectWithKey(option, 'type')) {
                return option.type;
              }
            }}
            renderOption={(props, option, { selected }) => {
              if (isObjectWithKey(option, 'label')) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.label}
                  </li>
                );
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Entities"
                error={errors.selectedEntities && !isValid}
                helperText={errors.selectedEntities?.message}
              />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{getFullExchangeTypeName(params.group)}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            PaperComponent={StyledPaper}
            value={field.value}
            isOptionEqualToValue={(option, value) => {
              if (isObjectWithKey(option, 'label')) {
                return option.label === value;
              }
              return false;
            }}
            onChange={(event, newValues) => {
              if (Array.isArray(newValues)) {
                const extractedNewValues = [...newValues].map((val) => {
                  if (typeof val === 'string') {
                    return val;
                  }
                  return val.label;
                });
                field.onChange(extractedNewValues);
              }
            }}
            onBlur={field.onBlur}
          />
        );
      }}
      name="selectedEntities"
      control={formMethods.control}
    />
  );
};
