import { ISeverityLevel } from '@/types/severityLevel';

export const severityLevels: ISeverityLevel[] = [
  { name: 'None', value: 'none' },
  { name: 'Low', value: 'low' },
  { name: 'Medium', value: 'medium' },
  { name: 'High', value: 'high' },
  { name: 'Extreme', value: 'extreme' },
];

export const SEVERITY_LEVELS = ['extreme', 'high', 'medium', 'low', 'none'] as const;
