import { useCallback, useContext, useEffect, useState } from 'react';
import { NewAuthContext } from '@/providers/AuthProvider/NewAuthProvider';
import { createAuthApiClient, createAuthUserApiClient } from '@/providers/AuthProvider/createAuthApiClient';
import { assertIsDefined } from '@/helpers/assertions';
import { useLogInUser } from '@/api/auth/auth.hooks';
import { ELocalStorage } from '@/types/storage';
import { ILogInRequest } from '@/api/auth/auth.types';
import { INewAuthContext } from '@/providers/AuthProvider/NewAuthProvider.types';
import { IUser } from '@/types/user';
import { useGetUser } from '@/api/user/user.hooks';
import { useTracking } from '@/hooks/useTracking';
import { isTokenExpired } from '@/hooks/useUserIdFromToken';

export const useNewAuth = (): INewAuthContext => {
  const { logIn, loginError, logOut, token, user, isAuthenticated, setRememberMe } = useContext(NewAuthContext);

  assertIsDefined(logIn, 'logIn must be defined');
  assertIsDefined(logOut, 'logOut must be defined');
  assertIsDefined(setRememberMe, 'setRememberMe must be defined');

  return {
    logIn,
    loginError: loginError || null,
    logOut,
    token: token || null,
    user: user || null,
    isAuthenticated: !!isAuthenticated,
    setRememberMe,
  };
};

export const useAuthManager = () => {
  const [token, setToken] = useState<string | null | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //TODO: This is a temporary solution until we have the remember me functionality implemented, for that reason it is passed always as true.
  const [rememberMe, setRememberMe] = useState(true);
  const [user, setUser] = useState<IUser | null>(null);

  const { resetTracking, trackLogin, identifyUser } = useTracking();
  const { authApi } = createAuthApiClient();
  const { userApi } = createAuthUserApiClient();

  const updateToken = (newToken: string, rememberMe: boolean) => {
    setToken(newToken);

    const storage = rememberMe ? localStorage : sessionStorage;
    const oppositeStorage = rememberMe ? sessionStorage : localStorage;
    storage.setItem(ELocalStorage.ACCESS_TOKEN, newToken);
    clearStorage(oppositeStorage);
  };

  const resetToken = () => {
    setToken(null);
    setIsAuthenticated(false);
  };

  const updateUser = (user: IUser, rememberMe: boolean) => {
    setUser(user);

    const storage = rememberMe ? localStorage : sessionStorage;
    const oppositeStorage = rememberMe ? sessionStorage : localStorage;

    storage.setItem(ELocalStorage.USER, JSON.stringify(user));
    clearStorage(oppositeStorage);
  };

  const resetUser = () => {
    setUser(null);
  };

  const clearStorage = (storage: Storage) => {
    storage.removeItem(ELocalStorage.USER);
    storage.removeItem(ELocalStorage.ACCESS_TOKEN);
  };

  const setCurrentUserData = useCallback(() => {
    const currentUserFromStorage =
      localStorage.getItem(ELocalStorage.USER) || sessionStorage.getItem(ELocalStorage.USER);

    if (currentUserFromStorage) {
      setUser(JSON.parse(currentUserFromStorage));
    }
  }, []);

  const setCurrentAuthenticatedUser = useCallback(() => {
    const accessTokenFromStorage =
      localStorage.getItem(ELocalStorage.ACCESS_TOKEN) || sessionStorage.getItem(ELocalStorage.ACCESS_TOKEN);

    if (accessTokenFromStorage) {
      if (isTokenExpired(accessTokenFromStorage)) {
        logOut();
        return window.location.reload();
      }

      setToken(accessTokenFromStorage);
      return setIsAuthenticated(true);
    }

    setToken(null);
  }, []);

  useEffect(() => {
    // refresh user session
    setCurrentAuthenticatedUser();

    setCurrentUserData();
  }, []);

  const { fetchingUser } = useGetUser(userApi);

  const { logInUser, error: loginError } = useLogInUser(authApi, {
    onSuccess: (authResponse) => {
      updateToken(authResponse.access_token, rememberMe);
      setIsAuthenticated(true);
      fetchingUser(authResponse.access_token, {
        onSuccess: (userDto) => {
          const userDataToUpdate = {
            userName: userDto.firstName,
            userId: userDto.id,
            permissions: userDto.permissions,
          };
          updateUser(userDataToUpdate, rememberMe);
          identifyUser(userDto);
          const userPermissions = userDto.permissions.join(', ');
          trackLogin(userDto.emailAddress, userPermissions);
        },
        onError: (error) => {
          console.error('Fetching user after login failed: ', error.message);
        },
      });
    },
  });

  const logIn = (logInCredentials: ILogInRequest, onSuccess?: () => void, onError?: () => void) => {
    logInUser(logInCredentials, { onSuccess, onError });
  };

  const logOut = () => {
    resetTracking();
    resetToken();
    clearStorage(localStorage);
    clearStorage(sessionStorage);
    resetUser();
  };

  return {
    logIn,
    loginError,
    logOut,
    token,
    user,
    isAuthenticated,
    setRememberMe,
  };
};
