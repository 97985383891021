import { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ProtectAdminRoute from './ProtectAdminRoutes';
import AdminRoutes from '@/routes/AdminRoutes';
import AdminLayout from '@/pages/AdminLayout';
import AlertsPage from '@/pages/AlertsPage';
import LoginPage from '@/pages/LoginPage';
import SettingsPage from '@/pages/SettingsPage';
import MainLayout from '@/layouts/MainLayout/MainLayout';
import { useTracking } from '@/hooks/useTracking';
import { RatingsPage } from '@/pages/Ratings/RatingsPage';
import { EntityRatingsPage } from '@/pages/Ratings/EntityRatingsPage';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { ELocalStorage } from '@/types/storage';
import { ERoutes } from '@/types/routes';
import { EntityRatingsRoute } from '@/routes/EntityRatingsRoute';
import RiskSimulatorPage from '@/pages/RiskSimulator/RiskSumulatorPage';
import { RiskSimulatorPortfolioPage } from '@/pages/RiskSimulator/RiskSimulatorPortfolioPage';
import MonitoringDashboardPage from '@/pages/Monitoring/MonitoringDashboardPage';
import OldLayout from '@/layouts/OldLayout';
import MonitoringSettingsPage from '@/pages/Monitoring/MonitoringSettingsPage';
import { EntityMonitoringPage } from '@/pages/Monitoring/EntityMonitoringPage';
import { DashboardTypeRoute } from '@/routes/DashboardTypeRoute';
import MonitoringPage from '@/pages/Monitoring/MonitoringPage';

const AppRoutes = () => {
  const { trackPageView } = useTracking();
  const { isAuthenticated } = useNewAuth();
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location?.pathname, trackPageView]);

  useEffect(() => {
    const currentPath = location.pathname;
    //Regex to validate mongo object id with the slash at the end
    const idPatternWithSlash = /^\/[0-9a-fA-F]{24}\/$/;
    //Regex to validate mongo object id without the slash at the end
    const idPatternWithoutSlash = /^\/[0-9a-fA-F]{24}$/;

    const anomalyViewRequested = idPatternWithSlash.test(currentPath) || idPatternWithoutSlash.test(currentPath);

    if (anomalyViewRequested && !isAuthenticated) {
      localStorage.setItem(ELocalStorage.REDIRECT_URL, currentPath);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        path={ERoutes.RATINGS}
        element={
          <PrivateRoute>
            <OldLayout header={'ratings'}>
              <RatingsPage />
            </OldLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={`${ERoutes.RATINGS}/:entityName`}
        element={
          <PrivateRoute>
            <EntityRatingsRoute>
              <OldLayout header={'entityRatings'}>
                <EntityRatingsPage />
              </OldLayout>
            </EntityRatingsRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.ALERTS}
        element={
          <PrivateRoute>
            <MainLayout header={'alerts'}>
              <AlertsPage />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={`${ERoutes.ALERTS}/:id`}
        element={
          <PrivateRoute>
            <MainLayout header={'alerts'}>
              <AlertsPage />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.RISK_SIMULATOR}
        element={
          <PrivateRoute>
            <OldLayout header={'riskSimulator'}>
              <RiskSimulatorPage />
            </OldLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.RISK_SIMULATOR_PORTFOLIO}
        element={
          <PrivateRoute>
            <OldLayout header={'riskSimulator'}>
              <RiskSimulatorPortfolioPage />
            </OldLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.MONITORING}
        element={
          <PrivateRoute>
            <MainLayout header={'monitoringHomepage'}>
              <MonitoringPage />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={`${ERoutes.MONITORING}/:dashboardType`}
        element={
          <PrivateRoute>
            <DashboardTypeRoute>
              <MainLayout header={'monitoringDashboardType'}>
                <MonitoringDashboardPage />
              </MainLayout>
            </DashboardTypeRoute>
          </PrivateRoute>
        }
      />
      <Route
        path={`${ERoutes.MONITORING_ENTITY}/:entityName`}
        element={
          <PrivateRoute>
            <MainLayout header={'entityMonitoring'}>
              <EntityMonitoringPage />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.MONITORING_SETTINGS}
        element={
          <PrivateRoute>
            <MainLayout header={'monitoringSettings'}>
              <MonitoringSettingsPage />
            </MainLayout>
          </PrivateRoute>
        }
      />
      <Route
        path={ERoutes.SETTINGS}
        element={
          <PrivateRoute>
            <OldLayout>
              <SettingsPage />
            </OldLayout>
          </PrivateRoute>
        }
      />
      <Route path="*" element={isAuthenticated ? <Navigate to={ERoutes.MONITORING} /> : <LoginPage />} />
      <Route
        path={`${ERoutes.ADMIN}/*`}
        element={
          <ProtectAdminRoute>
            <OldLayout>
              <AdminLayout />
            </OldLayout>
          </ProtectAdminRoute>
        }
      >
        {AdminRoutes()}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
