import { PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ERoutes } from '@/types/routes';
import { isDashboardTypeKey } from '@/helpers/typeGuards';

export const DashboardTypeRoute = ({ children }: PropsWithChildren) => {
  const { dashboardType } = useParams();

  if (dashboardType && isDashboardTypeKey(dashboardType)) {
    return <>{children}</>;
  }

  return <Navigate to={ERoutes.MONITORING} replace />;
};
