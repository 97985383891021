import { useCallback, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { format } from 'date-fns';
import AgioRiskPdfStample from '@/assets/png/agioRiskPdfStample.png';
import {
  counterpartyHeaders,
  disclaimerText,
  noteText,
} from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/utils/constants';
import {
  generateCounterpartyData,
  generateRiskMeasuresData,
  isRowInput,
} from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/utils/helpers';
import { IParametersOfSimulation, IResultOfRiskSimulation } from '@/views/RiskSimulatorPortfolioView/utils/types';

interface IUseFullReportPdfManagerProps {
  parametersOfDisplayedSimulation: IParametersOfSimulation;
  resultOfRiskSimulation: IResultOfRiskSimulation;
}

export const useFullReportPdfManager = ({
  parametersOfDisplayedSimulation,
  resultOfRiskSimulation,
}: IUseFullReportPdfManagerProps) => {
  const [logoBase64, setLogoBase64] = useState('');

  const generatePDF = useCallback(() => {
    const { pdSelectedDate, rows, correlation, numberOfExchanges, contagionLevel } = parametersOfDisplayedSimulation;

    // Create a new PDF document
    const doc = new jsPDF();

    // Shared variables
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageMargins = 15;
    const dateFontSize = 9;
    let currentY = 40;
    const initialCursorPositionForNextPage = 10;
    const tableTitleSpacing = 3; // Space between the title and table

    // Function definitions
    const createTable = (doc: jsPDF, data: RowInput[], startY: number, headers?: string[]) => {
      const tableMargin = 5; // Space between tables
      const cellPadding = 2; // Space inside cells
      const rowTableHeight = 8; // Approximate height of each row in the table

      autoTable(doc, {
        startY: startY, // Start position for the table
        ...(headers ? { head: [headers] } : {}),
        body: data,
        theme: 'plain',
        styles: {
          cellPadding: cellPadding, // Padding inside cells
        },
        margin: { top: tableMargin },
        willDrawCell: (data) => {
          doc.setLineWidth(0.5); // Set thicker line width
          doc.setDrawColor(0, 0, 0); // Set color for the line

          if (data.section === 'head' || data.row.index === 0) {
            const { doc, cell } = data;
            // Draw top border for the first row (header)
            doc.line(cell.x, cell.y, cell.x + cell.width, cell.y);
          }

          if (data.section === 'body') {
            const { doc, cell } = data;
            // Draw only the bottom border (horizontal line)
            doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);
          }
        },
        didDrawCell: (data) => {
          // Ensure `data.cursor` and `data.cell` are valid before accessing properties
          if (data.section === 'body' && data.cursor && data.cell) {
            currentY = data.cursor.y + 2 * cellPadding + tableMargin; // Update Y position after drawing cells
          }
        },
      });

      // Update currentY for the next table
      currentY += rowTableHeight + tableMargin;

      // Change Page if currentY is too big
      if (currentY >= pageHeight - pageMargins) {
        doc.addPage();
        currentY = initialCursorPositionForNextPage;
      }
    };

    // Function checks if there is enough space on the page to display block of text
    const checkPageSpace = (currentY: number, blockHeight: number) => {
      if (currentY + blockHeight > pageHeight) {
        doc.addPage();
        return initialCursorPositionForNextPage;
      }
      return currentY;
    };

    // >>>START__________________CREATE_LOGO_____________________START<<<
    if (logoBase64) {
      const logoWidth = 80;
      const logoHeight = 15;
      const logoPositionY = 6;

      doc.addImage(logoBase64, 'PNG', pageMargins, logoPositionY, logoWidth, logoHeight);
    }
    // >>>END__________________CREATE_LOGO_____________________END<<<

    // >>>START__________________ADD_DATES_____________________START<<<
    const today = format(new Date(), 'yyyy-MM-dd');
    const tearSheetPositionY = 10;
    const PDDatePositionY = 15;

    doc.setFontSize(dateFontSize);

    doc.text('Tear sheet generated on:', 138, tearSheetPositionY);
    doc.text(today, 178, tearSheetPositionY);
    doc.text('PD data from: ', 154, PDDatePositionY);
    doc.text(pdSelectedDate || '', 178, PDDatePositionY);
    // >>>END__________________ADD_DATES_____________________END<<<

    // >>>START__________________COUNTERPARTY_EXPOSURES_____________________START<<<
    const counterpartyData = generateCounterpartyData(rows);

    // Generate table: Counterparty Exposures
    doc.setFontSize(12);
    doc.text('Counterparty Exposures', pageMargins, currentY - tableTitleSpacing);

    createTable(doc, counterpartyData, currentY, counterpartyHeaders);
    // >>>END__________________COUNTERPARTY_EXPOSURES_____________________END<<<

    // >>>START__________________RISK_MEASURES_____________________START<<<
    const riskMeasuresData = generateRiskMeasuresData(resultOfRiskSimulation, true);

    // Generate table: Risk Measures
    doc.text('Risk Measures', pageMargins, currentY);

    currentY = currentY + tableTitleSpacing;

    if (isRowInput(riskMeasuresData)) {
      createTable(doc, riskMeasuresData, currentY);
    }
    // >>>END__________________RISK_MEASURES_____________________END<<<

    // >>>START__________________SIMULATION_PARAMETERS_____________________START<<<
    const simulationParametersData = [
      ['Number of Counterparties', numberOfExchanges],
      ['Number of Simulations', '1,000,000'],
      ['Contagion Risk Level', contagionLevel],
      ['Correlation Multiplier', correlation],
    ];

    // Generate table: Simulation Parameters
    doc.text('Simulation Parameters', pageMargins, currentY);

    currentY = currentY + tableTitleSpacing;

    createTable(doc, simulationParametersData, currentY);
    // >>>END__________________SIMULATION_PARAMETERS_____________________END<<<

    // >>>START__________________INFO_TEXT_____________________START<<<
    doc.setFontSize(8);
    // Calculate width and height of the text on the page
    const maxLineWidth = pageWidth - pageMargins * 2;
    const lineHeight = 6; // Approximate line height for font size = 8;

    // Divide texts into lines with appropriate width
    const splitNoteText = doc.splitTextToSize(noteText, maxLineWidth);
    const splitDisclaimerText = doc.splitTextToSize(disclaimerText, maxLineWidth);

    const noteHeight = splitNoteText.length * lineHeight;
    const disclaimerHeight = splitDisclaimerText.length * lineHeight;

    // Generate note txt
    doc.text(splitNoteText, pageMargins, currentY);

    currentY = currentY + noteHeight;

    // Check position for the next block of text
    currentY = checkPageSpace(currentY, disclaimerHeight);

    // Generate disclaimer txt
    doc.text(splitDisclaimerText, pageMargins, currentY);
    // >>>END__________________INFO_TEXT_____________________END<<<

    // Save PDF
    doc.save('risk_report.pdf');
  }, [logoBase64, parametersOfDisplayedSimulation, resultOfRiskSimulation]);

  useEffect(() => {
    const toBase64 = (url: string) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.setAttribute('crossOrigin', 'anonymous'); // To avoid CORS issues
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        };
        img.onerror = (err) => {
          reject(err);
        };
      });
    };

    // Convert the imported logo image to Base64
    toBase64(AgioRiskPdfStample).then((base64) => {
      if (typeof base64 === 'string') {
        setLogoBase64(base64);
      }
    });
  }, []);

  return {
    generatePDF,
  };
};
