import React from 'react';
import { format } from 'date-fns';
import { useTrackTimeSpentOnMount } from '@/hooks/useTrackTimeSpent';
import { getCalendarDaysDifference } from '@/helpers/helpers';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import { TSeverity } from '@/types/severityLevel';

interface AlertDetailsPanelProps {
  companyName: string;
  timestamp: string;
  variable: string;
  z_score: number;
  severity: TSeverity;
  entityType: string;
  isRated: boolean;
}

interface DetailProps {
  label: string;
  value: string | JSX.Element;
}

const Detail: React.FC<DetailProps> = ({ label, value }) => (
  <div
    style={{
      display: 'flex',
      width: '211px',
      justifyContent: 'space-between',
      margin: '0',
      padding: '0',
      alignItems: 'center',
    }}
  >
    <span style={{ color: '#FFF', fontSize: '14px', fontWeight: 400, opacity: 0.5 }}>{label}</span>
    <span style={{ color: '#FFF', fontSize: '14px', fontWeight: 400 }}>{value}</span>
  </div>
);

const AlertDetailsPanel: React.FC<AlertDetailsPanelProps> = ({
  companyName,
  timestamp,
  variable,
  z_score,
  severity,
  entityType,
  isRated,
}) => {
  const { getExchangeLabelByDisplayName } = useEntitiesList();
  useTrackTimeSpentOnMount('Alert details panel', {
    companyName,
    timestamp,
    variable,
    z_score,
    severity,
  });

  return (
    <div
      style={{
        display: 'flex',
        width: '213px',
        height: 'auto',
        padding: '15px 25px 30px 25px',
        alignItems: 'center',
        gap: '16px',
        flexShrink: 0,
        borderRadius: '4px 4px 0px 0px',
        border: '1px solid #2E2E31',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          height: '57px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '16px',
          borderBottom: '1px solid #2e2e31',
          width: '263px',
          paddingBottom: '15px',
        }}
      >
        <ExchangeLogo companyName={getExchangeLabelByDisplayName(companyName)} />
        <h3 style={{ color: '#FFF', fontSize: '24px', fontWeight: 400 }}>{companyName}</h3>
      </div>
      <div
        style={{
          display: 'inline-flex',
          height: 'auto',
          alignItems: 'flex-start',
          gap: '32px',
          flexShrink: 0,
          flexDirection: 'column',
        }}
      >
        <h4
          style={{
            color: '#FFF',
            fontSize: '14px',
            fontWeight: 400,
            opacity: 0.5,
            letterSpacing: '4.8px',
          }}
        >
          ALERT DETAILS
        </h4>
        <Detail label="Severity" value={<AlertSeverityBarsIcon severity={severity} />} />
        <Detail label="Date" value={format(new Date(timestamp), 'LLLL d, yyyy')} />
        <Detail label="Alert occurred" value={getCalendarDaysDifference(timestamp)} />
        <Detail label="Entity type" value={entityType.toUpperCase()} />
        <Detail label="Variable" value={variable} />
        <Detail label="Is rated" value={isRated ? 'Rated' : 'Not rated'} />
      </div>
    </div>
  );
};

export default AlertDetailsPanel;
