import { Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@/assets/icons/help.svg';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import { TrendingRisk } from '@/views/EntityMonitoringView/components/EntityDetails/components/TrendingRisk/TrendingRisk';
import {
  StyledRowDataWrapper,
  StyledTooltipContent,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const ShortTermRisk = ({
  actualSeverity,
  riskIndicator,
}: Pick<IEntityDetailsProps, 'actualSeverity' | 'riskIndicator'>) => {
  return (
    <>
      <Typography color="white.100" fontSize="14px">
        Short term risk
      </Typography>
      <StyledRowDataWrapper>
        <div>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            placement="left"
            title={
              <p>
                Our alert system now categorizes anomalies into five distinct severity levels using a percentile-based
                approach. This method uses historical data to define what is expected versus increasingly severe
                anomalies. The result is a more actionable framework that helps users quickly assess the importance of
                each alert.
              </p>
            }
          >
            <StyledTooltipContent>
              <Typography color="#ffffff80" fontSize="14px">
                Actual severity
              </Typography>
              <img src={HelpIcon} alt="Help icon" width="16px" height="16px" />
            </StyledTooltipContent>
          </Tooltip>
        </div>
        <AlertSeverityBarsIcon severity={actualSeverity} />
      </StyledRowDataWrapper>

      <StyledRowDataWrapper>
        <TrendingRisk riskIndicator={riskIndicator} />
      </StyledRowDataWrapper>
    </>
  );
};
