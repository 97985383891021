import { SyntheticEvent, useEffect, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { defaultContagionLevels } from '@/views/RiskSimulatorPortfolioView/utils/constants';
import { linspace } from '@/helpers/stats';
import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { SectionTitle } from '@/views/RiskSimulatorPortfolioView/components/SectionTitle/SectionTitle';
import {
  StyledRadio,
  StyledRadioWrapper,
  StyledSeparator,
  StyledWrapper,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/ContagionLevel/ContagionLevel.styled';
import { IContagionLevel, TContagionLevel } from '@/views/RiskSimulatorPortfolioView/utils/types';

export const ContagionLevel = () => {
  const { riskSimulatorMethods, watchedRowsValues } = useRiskSimulator();
  const { setValue } = riskSimulatorMethods;

  const contagionLevel = useWatch({
    name: 'contagionLevel',
    control: riskSimulatorMethods.control,
  });

  const maxCorrelation = useMemo(() => {
    const maxPd = watchedRowsValues.reduce((acc, row) => Math.max(acc, row.pd), 0);
    const maxSelectorMultiplier = (1 / maxPd) * 100;

    return Math.floor(maxSelectorMultiplier * 100) / 100;
  }, [watchedRowsValues]);

  const contagionLevels: IContagionLevel[] = useMemo(() => {
    if (maxCorrelation && maxCorrelation !== Infinity) {
      const stopValue = Math.min(maxCorrelation, 5) - 1;

      const generatedCorrelationMultipliers = linspace(0, stopValue, 5).map((num) => num + 1);

      return defaultContagionLevels.map((cl, i) => {
        return { ...cl, correlation: Math.floor(generatedCorrelationMultipliers[i] * 100) / 100 };
      });
    }

    return defaultContagionLevels;
  }, [maxCorrelation]);

  useEffect(() => {
    const selectedContagionLevel = contagionLevels.find((contagion) => contagion.value === contagionLevel.value);

    if (selectedContagionLevel) {
      if (selectedContagionLevel.correlation !== contagionLevel.correlation) {
        setValue('contagionLevel', { ...contagionLevel, correlation: selectedContagionLevel.correlation });
      }
    }
  }, [contagionLevels, contagionLevel, setValue]);

  return (
    <StyledWrapper>
      <SectionTitle
        title="Contagion Risk Level"
        tooltipDescription="This is the risk associated with defaults being correlated to each other in the digital asset market. By default, the Risk Simulator opts for a “Medium” contagion risk level. A higher contagion risk corresponds to higher default correlation whilst no contagion risk corresponds to no default correlation."
      />
      <StyledSeparator />

      <StyledRadioWrapper>
        <FormControl>
          <Controller
            render={({ field }) => {
              const handleRadioChange = (e: SyntheticEvent<Element, Event>) => {
                const value = (e.target as HTMLInputElement).value as TContagionLevel;

                const selectedContagionLevels = contagionLevels.find((contagion) => contagion.value === value);

                field.onChange(selectedContagionLevels);
              };

              return (
                <RadioGroup
                  row
                  aria-labelledby="contagion-level-radio-group"
                  {...field}
                  name="contagion-level-radio-group"
                  onChange={(e) => handleRadioChange(e)}
                >
                  {contagionLevels.map((contagion) => {
                    return (
                      <FormControlLabel
                        key={contagion.name}
                        value={contagion.value}
                        checked={contagion.value === field.value.value}
                        control={<StyledRadio />}
                        label={
                          <Typography color="white.100" fontSize="13px">
                            {contagion.name}
                          </Typography>
                        }
                        labelPlacement="bottom"
                      />
                    );
                  })}
                </RadioGroup>
              );
            }}
            name="contagionLevel"
            control={riskSimulatorMethods.control}
          />
        </FormControl>
      </StyledRadioWrapper>
    </StyledWrapper>
  );
};
