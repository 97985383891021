import { useRatings } from '@/providers/RatingsListContextProvider/RatingsListContext.hooks';
import Loader from '@/components/atoms/Loader/Loader';
import { RatingsView } from '@/views/RatingsView/RatingsView';
import { StyledLoaderContainer } from './RatingsView.styled';

export const Ratings = () => {
  const { ratings, isLoadingRatings, ratingsError } = useRatings();

  if (isLoadingRatings) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  if (ratingsError) {
    throw ratingsError;
  }

  if (ratings && !ratings.data.length) {
    throw Error('Not found ratings data');
  }

  return (
    <RatingsView
      fetchedRatings={ratings.data}
      industryAverage={ratings.industryAverage}
      topMovers={ratings.topMovers}
    />
  );
};
