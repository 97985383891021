import { useMemo, useState } from 'react';
import { isEqual, uniqWith } from 'lodash';
import { TableBody, TableHead, Typography } from '@mui/material';
import { INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { AnomalyTableRow } from '@/views/EntityMonitoringView/components/AnomaliesTable/components/AnomalyTableRow/AnomalyTableRow';
import {
  StyledWrapper,
  StyledPagination,
  StyledNoRows,
  StyledHeaderCell,
  StyledTable,
  StyledHeaderTableRow,
  StyledTableWrapper,
} from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable.styled';
import { IAnomalyDto } from '@/types/anomaly';

interface IAnomaliesTableProps {
  latestAnomalies: IAnomalyDto[];
}

export const AnomaliesTable = ({ latestAnomalies }: IAnomaliesTableProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const filteredAnomalies = useMemo(() => {
    return latestAnomalies.filter((anomaly) => anomaly.severity === 'high' || anomaly.severity === 'extreme');
  }, [latestAnomalies]);

  const uniqueAnomalies = useMemo(() => {
    return uniqWith(
      filteredAnomalies,
      (a, b) => isEqual(a.severity, b.severity) && isEqual(a.counterParties, b.counterParties),
    );
  }, [filteredAnomalies]);

  const totalNumberOfFilteredItems = useMemo(() => {
    return uniqueAnomalies.length;
  }, [uniqueAnomalies]);

  const paginatedAnomaliesList = useMemo(() => {
    const paginatedBuckets: IAnomalyDto[][] = [];

    uniqueAnomalies.forEach((anomaly, index) => {
      if (index % 5 === 0) {
        paginatedBuckets.push([]);
      }

      paginatedBuckets[paginatedBuckets.length - 1].push(anomaly);
    });

    return paginatedBuckets;
  }, [uniqueAnomalies]);

  if (!totalNumberOfFilteredItems) {
    return (
      <StyledWrapper>
        <Typography color="white.100" fontSize="24px" noWrap>
          Anomalies
        </Typography>
        <StyledNoRows>No anomalies with extreme or high severities were found in the last 24 hours.</StyledNoRows>
      </StyledWrapper>
    );
  }

  return (
    <>
      <StyledWrapper>
        <Typography color="white.100" fontSize="24px">
          Anomalies
        </Typography>

        <StyledTableWrapper>
          <StyledTable aria-label="anomalies table">
            <TableHead>
              <StyledHeaderTableRow>
                <StyledHeaderCell />
                <StyledHeaderCell>Severity</StyledHeaderCell>
                <StyledHeaderCell>Timestamp</StyledHeaderCell>
                <StyledHeaderCell>Variable</StyledHeaderCell>
                <StyledHeaderCell>Anomaly details</StyledHeaderCell>
              </StyledHeaderTableRow>
            </TableHead>
            <TableBody>
              {paginatedAnomaliesList[currentPage - 1].map((row) => (
                <AnomalyTableRow key={row.id} row={row} />
              ))}
            </TableBody>
          </StyledTable>
        </StyledTableWrapper>
        <StyledPagination
          totalNumberOfItems={totalNumberOfFilteredItems}
          pageSize={INITIAL_PAGE_SIZE}
          currentPage={currentPage}
          setPage={setCurrentPage}
        />
      </StyledWrapper>
    </>
  );
};
