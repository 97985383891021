import styled from '@emotion/styled';

//TODO: this is a temporary solution, styles of the view must be improved with border-box setup
export const StyledTemporaryFix = styled.div`
  * {
    box-sizing: content-box;
  }
`;

export const StyledChartsWrapper = styled.div`
  padding: 34px;
  border: 1px solid #2e2e31;
  border-radius: 4px;
  flex-basis: 100%;
`;
