import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertsList } from '@/views/AlertsView/components/AlertsList/AlertsList';
import AlertDetails from '@/views/AlertsView/components/AlertDetails';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';
import { CommonError } from '@/components/Error/CommonError/CommonError';
import { StyledAlertDetailsContainer, StyledPageContainer, StyledTextContainer } from './AlertsView.styled';
import { ERoutes } from '@/types/routes';
import { useTracking } from '@/hooks/useTracking';
import { Link, Typography } from '@mui/material';

export const AlertsView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isGridCollapsed, setGridCollapsed] = useState(false);
  const { trackEvent } = useTracking();

  const handleSelectedAlert = (rowId: string) => {
    setGridCollapsed(true);
    trackEvent('Alerts - details click', {
      alert: rowId,
    });
    navigate(`${ERoutes.ALERTS}/${rowId}`);
  };

  const handleToggleCollapse = () => {
    setGridCollapsed((prev) => !prev);
    navigate(ERoutes.ALERTS);
  };

  useEffect(() => {
    if (id) {
      setGridCollapsed(true);
    }
  }, [id]);

  return (
    <>
      <StyledTextContainer>
        <Typography color="white.100" align="center">
          We’re excited to introduce our new{' '}
          <Link href={ERoutes.MONITORING} underline="none">
            <strong>Risk Monitoring Tool</strong>
          </Link>
          , which provides real-time, 24/7 insights into your risk landscape. <br /> Building on our previous alerts
          system, this tool offers enhanced customization and continuous monitoring to help you better manage risk
          across your entities.
        </Typography>
      </StyledTextContainer>

      <StyledPageContainer>
        <AlertsList
          isGridCollapsed={isGridCollapsed}
          handleToggleCollapse={handleToggleCollapse}
          handleSelectedAlert={handleSelectedAlert}
        />

        {isGridCollapsed && (
          <ErrorBoundary fallback={<CommonError variant="empty" />}>
            <StyledAlertDetailsContainer>
              <AlertDetails />
            </StyledAlertDetailsContainer>
          </ErrorBoundary>
        )}
      </StyledPageContainer>
    </>
  );
};
