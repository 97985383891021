import { Typography } from '@mui/material';
import { formatToUSD } from '@/helpers/helpers';
import { StyledRowDataWrapper } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const SignificantData = ({
  balance,
  outflow,
  inflow,
  totalFlow,
  netflow,
}: Pick<IEntityDetailsProps, 'balance' | 'outflow' | 'inflow' | 'totalFlow' | 'netflow'>) => {
  return (
    <>
      <Typography color="white.100" fontSize="14px">
        On-chain data
      </Typography>
      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          Balance
        </Typography>
        <Typography color="white.100" fontSize="16px" align="right">
          {balance ? formatToUSD(balance, true) : ''}
        </Typography>
      </StyledRowDataWrapper>
      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          24hr Outflows
        </Typography>
        <Typography color="white.100" fontSize="16px" align="right">
          {outflow ? formatToUSD(outflow, true) : ''}
        </Typography>
      </StyledRowDataWrapper>
      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          24hr Inflows
        </Typography>
        <Typography color="white.100" fontSize="16px" align="right">
          {inflow ? formatToUSD(inflow, true) : ''}
        </Typography>
      </StyledRowDataWrapper>
      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          24hr Total flows
        </Typography>
        <Typography color="white.100" fontSize="16px" align="right">
          {totalFlow ? formatToUSD(totalFlow, true) : ''}
        </Typography>
      </StyledRowDataWrapper>
      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          24hr Net flows
        </Typography>
        <Typography color={netflow && netflow > 0 ? 'green' : 'red'} fontSize="16px" align="right">
          {netflow ? formatToUSD(netflow, true) : ''}
        </Typography>
      </StyledRowDataWrapper>
    </>
  );
};
