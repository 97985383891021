import { RefObject } from 'react';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import { Typography } from '@mui/material';
import DownloadIcon from '@/assets/icons/download.svg?react';
import { SectionTitle } from '@/views/RiskSimulatorPortfolioView/components/SectionTitle/SectionTitle';
import { NumberOfDefaultsChart } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/components/NumberOfDefaultsChart/NumberOfDefaultsChart';
import {
  StyledButton,
  StyledHeaderWrapper,
  StyledSection,
} from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/SimulatedCharts.styled';
import { TTransformedDataFrequency } from '@/views/RiskSimulatorPortfolioView/utils/types';

interface INumberOfDefaultsSectionProps {
  defaultsFrequency: TTransformedDataFrequency[];
  numberOfDefaultsRef: RefObject<HighchartsReactRefObject>;
  handleDownloadFullDistribution: () => void;
}

export const NumberOfDefaultsSection = ({
  defaultsFrequency,
  numberOfDefaultsRef,
  handleDownloadFullDistribution,
}: INumberOfDefaultsSectionProps) => {
  return (
    <StyledSection>
      <StyledHeaderWrapper>
        <SectionTitle
          title="Number of Defaults"
          tooltipDescription={
            <>
              <p>
                A bar plot of the frequency at which a certain number of defaults occur in the next year. The frequency
                is obtained from 1M+ Monte-Carlo simulations.
              </p>
              <p>
                The empirical probability mass function of the number of defaults can be downloaded as a csv file using
                the &quot;Download Full Distribution&quot; button.
              </p>
            </>
          }
        />

        <StyledButton
          variant="outlined"
          color="tertiary"
          fitContentWidth
          skipTypography
          onClick={handleDownloadFullDistribution}
        >
          <DownloadIcon />
          <Typography noWrap style={{ font: 'inherit' }}>
            Download Full Loss Distribution
          </Typography>
        </StyledButton>
      </StyledHeaderWrapper>

      <NumberOfDefaultsChart defaultsFrequency={defaultsFrequency} chartRef={numberOfDefaultsRef} />
    </StyledSection>
  );
};
