import { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { UseFormReturn } from 'react-hook-form';
import { IDatePickerRange } from '@/types/datePicker';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { IDatePickerManager, IRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/utils/types';

interface IUseDatePickerManagerProps {
  ratings: IStatisticalRatingEntity[];
  formMethods: UseFormReturn<IRiskSimulatorForm>;
}

export const useDatePickerManager = ({ ratings, formMethods }: IUseDatePickerManagerProps) => {
  const availableDatesRange: IDatePickerRange = useMemo(
    () => ({
      start: dayjs(ratings[0].data[ratings[0].data.length - 1].timestamp.split('T')[0]),
      end: dayjs(ratings[0].data[0].timestamp.split('T')[0]),
    }),
    [ratings],
  );

  const [selectedDate, setSelectedDate] = useState<Dayjs>(availableDatesRange.end);

  const handleDatePickerChange = (newValue: unknown) => {
    if (dayjs.isDayjs(newValue)) {
      setSelectedDate(newValue);
    }
  };

  const timestamp = useMemo(() => {
    return selectedDate.format('YYYY-MM-DD');
  }, [selectedDate]);

  const { pdSelectedDate } = formMethods.watch();

  useEffect(() => {
    if (pdSelectedDate !== timestamp) {
      formMethods.setValue('pdSelectedDate', timestamp);
    }
  }, [formMethods, timestamp, pdSelectedDate]);

  const datePickerManager: IDatePickerManager = {
    availableDatesRange,
    selectedDate,
    handleDatePickerChange,
    formattedSelectedDate: timestamp,
  };

  return {
    datePickerManager,
  };
};
