import { RefObject } from 'react';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import { SectionTitle } from '@/views/RiskSimulatorPortfolioView/components/SectionTitle/SectionTitle';
import { NonZeroLossesChart } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/components/NonZeroLossesChart/NonZeroLossesChart';
import {
  StyledHeaderWrapper,
  StyledSection,
} from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/SimulatedCharts.styled';
import { TTransformedDataFrequency } from '@/views/RiskSimulatorPortfolioView/utils/types';

interface INonZeroLossesSectionProps {
  lossFrequency: TTransformedDataFrequency[];
  nonZeroLossesRef: RefObject<HighchartsReactRefObject>;
}

export const NonZeroLossesSection = ({ lossFrequency, nonZeroLossesRef }: INonZeroLossesSectionProps) => {
  return (
    <StyledSection>
      <StyledHeaderWrapper>
        <SectionTitle
          title="Severity of Non-Zero Losses"
          tooltipDescription="A histogram of the size of losses for the simulations where at least one default occurred in the next year. The size of each loss is expressed as a percentage of total exposure. The frequency is obtained from 1M+ Monte-Carlo simulations and the bin width is set at 5%."
        />
      </StyledHeaderWrapper>

      <NonZeroLossesChart lossFrequency={lossFrequency} chartRef={nonZeroLossesRef} />
    </StyledSection>
  );
};
