import React from 'react';
import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Paper } from '@mui/material';

interface MultiSelectProps {
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
  label?: string;
}

const StyledPaper = styled(Paper)`
  background: #1b1b1e;
  border-radius: 8px;
  color: #fff;

  .MuiAutocomplete-noOptions {
    color: #fff;
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  border-radius: 4px;
  background: #1b1b1e;
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  .MuiButtonBase-root {
    color: #fff;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
  }
  border-color: transparent;
  svg {
    fill: rgba(255, 255, 255, 0.54);
  }
  &.Mui-focused.MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
  }
  label {
    color: rgba(255, 255, 255, 0.54);
    &.Mui-focused {
      color: #5d51b8;
    }
  }
`;

const MultiSelect: React.FC<MultiSelectProps> = ({ options, value, onChange, label }) => {
  return (
    <StyledAutocomplete
      multiple
      disablePortal
      PaperComponent={({ children }) => <StyledPaper>{children}</StyledPaper>}
      options={options}
      onChange={(event, newValue) => {
        onChange(newValue as string[]);
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default MultiSelect;
