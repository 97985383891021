import styled from '@emotion/styled';

export const StyledDragList = styled.div`
  margin: 0 auto;
  padding-inline: 30px;
  width: 100%;
  max-width: 500px;
`;

export const StyledDragItem = styled.div<{ isDragging: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 0 0 8px 0;
  padding: 16px;
  border-radius: 6px;
  user-select: none;
  background-color: ${({ isDragging }) => (isDragging ? '#5d51b8' : '#222')};
  overflow: hidden;
`;
