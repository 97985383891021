import { TRENDING_RISK_OPTIONS } from '@/views/EntityMonitoringView/components/TrendingRiskChart/utils/constants';
import { ISpecificOption } from '@/types/select';

export type TrendingRiskOptionKey = (typeof TRENDING_RISK_OPTIONS)[number];

export type TTrendingRiskOption = ISpecificOption<TrendingRiskOptionKey>;

export const isTrendingRiskOptionKey = (value: string): value is TrendingRiskOptionKey => {
  return TRENDING_RISK_OPTIONS.includes(value as TrendingRiskOptionKey);
};
