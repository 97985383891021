import { IRiskSimulatorForm, IRow, IContagionLevel } from '@/views/RiskSimulatorPortfolioView/utils/types';

export const emptyRow: IRow = {
  entity: '',
  exposure: 100,
  percentExposure: 0,
  pd: 0,
  recovery: 0,
  isCustom: false,
};

export const defaultContagionLevel: IContagionLevel = { name: 'Medium', value: 'medium', correlation: 1 };

export const defaultRiskSimulatorFormValues: IRiskSimulatorForm = {
  rows: [emptyRow],
  contagionLevel: defaultContagionLevel,
  pdSelectedDate: '',
};

export const defaultContagionLevels: IContagionLevel[] = [
  { name: 'None', value: 'none', correlation: 1 },
  { name: 'Low', value: 'low', correlation: 2 },
  { name: 'Medium', value: 'medium', correlation: 3 },
  { name: 'High', value: 'high', correlation: 4 },
  { name: 'Very High', value: 'very-high', correlation: 5 },
];
