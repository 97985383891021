import {
  IAnomaliesEmailNotificationsForm,
  IMonitoringForm,
  TAnomaliesEmailNotificationsOption,
} from '@/views/MonitoringSettingsView/utils/types';

export const defaultMonitoringFormValues: IMonitoringForm = {
  entities: [],
  sortedEntities: [],
};

export const ANOMALIES_EMAIL_NOTIFICATIONS_OPTIONS = ['none', 'all', 'select'] as const;

export const anomaliesEmailNotificationsOptions: TAnomaliesEmailNotificationsOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Select entities manually',
    value: 'select',
  },
];

export const defaultAnomaliesEmailNotificationsFormValues: IAnomaliesEmailNotificationsForm = {
  notificationsMode: 'all',
  selectedEntities: [],
};
