import styled from '@emotion/styled';

export const StyledSpan = styled.span<{ layout: 'row' | 'column' }>`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  opacity: 0.5;
  text-transform: uppercase;
  height: ${(props) => props.layout === 'row' && '56px'};
`;

export const StyledSingleChartWrapper = styled.div<{ layout: 'row' | 'column' }>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

export const ChartsContainer = styled.div<{ layout: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => (props.layout === 'row' ? 'row' : 'column')};
  justify-content: space-between;
  min-width: 600px;
`;
