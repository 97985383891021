import { useMemo } from 'react';
import { trendingRiskChartOptions } from '@/views/EntityMonitoringView/components/TrendingRiskChart/utils/constants';
import { useTrendingRiskChartModeManager } from '@/views/EntityMonitoringView/components/TrendingRiskChart/utils/useTrendingRiskChartModeManager';
import { SeverityTrendingChart } from '@/components/molecules/Charts/SeverityTrendingChart';
import Select from '@/components/molecules/Select/Select';
import {
  StyledChartsWrapper,
  StyledSelectWrapper,
  StyledSpan,
} from '@/views/EntityMonitoringView/components/TrendingRiskChart/TrendingRiskChart.styled';
import { IAnomalyDto } from '@/types/anomaly';

interface ITrendingRiskChartProps {
  lastWeekAnomalies: IAnomalyDto[];
  lastDayAnomalies: IAnomalyDto[];
}

export const TrendingRiskChart = ({ lastWeekAnomalies, lastDayAnomalies }: ITrendingRiskChartProps) => {
  const lastWeekAnomaliesSeverityData = useMemo(
    () =>
      lastWeekAnomalies.map((anomaly) => {
        return {
          timestamp: anomaly.timestamp,
          value: anomaly.severity,
        };
      }),
    [lastWeekAnomalies],
  );

  const lastDayAnomaliesSeverityData = useMemo(
    () =>
      lastDayAnomalies.map((anomaly) => {
        return {
          timestamp: anomaly.timestamp,
          value: anomaly.severity,
        };
      }),
    [lastDayAnomalies],
  );

  const { selectedTrendingRiskChart, handleTrendingRiskChartChange, isHourlyChart, generatedChartData } =
    useTrendingRiskChartModeManager({
      lastWeekAnomalies: lastWeekAnomaliesSeverityData,
      lastDayAnomalies: lastDayAnomaliesSeverityData,
    });

  if (!lastWeekAnomalies.length) return null;

  return (
    <StyledChartsWrapper>
      <StyledSpan>24h Trending Risk</StyledSpan>
      <StyledSelectWrapper>
        <Select
          options={trendingRiskChartOptions}
          onChange={handleTrendingRiskChartChange}
          value={selectedTrendingRiskChart}
          clearVisible={false}
        />
      </StyledSelectWrapper>
      <SeverityTrendingChart
        key={selectedTrendingRiskChart}
        chartData={generatedChartData}
        isHourlyChart={isHourlyChart}
      />
    </StyledChartsWrapper>
  );
};
