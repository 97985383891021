import { useEffect, useRef } from 'react';
import { useTracking } from './useTracking';

export const useTrackTimeSpentOnMount = (eventName: string, eventProperties: Record<string, any>) => {
  const startTimeRef = useRef<number | null>(null);
  const { trackEvent } = useTracking();

  useEffect(() => {
    startTimeRef.current = Date.now();

    return () => {
      if (startTimeRef && startTimeRef.current) {
        const timeSpent = Date.now() - startTimeRef.current;
        trackEvent(eventName, {
          'duration seconds': Math.round(timeSpent / 1000),
          'duration minutes': Math.round(timeSpent / 1000 / 60),
        });
      }
    };
  }, [eventName, eventProperties]);
};
