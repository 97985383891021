import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { getFullExchangeTypeName } from '@/helpers/exchangeNames';
import {
  StyledImg,
  StyledLink,
  StyledTopPartWrapper,
} from '@/views/MonitoringView/components/DashboardCard/DashboardCard.styled';
import { DashboardTypeKey } from '@/types/dashboardTypes';
import { ERoutes } from '@/types/routes';

interface IDashboardCardProps {
  type: DashboardTypeKey;
}

const DESCRIPTION_CUSTOM_DASHBOARD = 'Create your own dashboard with mixed types of entities to monitor.';

export const DashboardCard = ({ type }: IDashboardCardProps) => {
  const imagePath = `/images/entityTypes/${type}.png`;
  const { exchanges } = useEntitiesList();
  const isCustom = type === 'custom';

  const typeExchangesNumber = useMemo(() => {
    if (exchanges.length > 0) {
      const currentTypeExchanges = exchanges.filter((exchange) => exchange.type === type);
      return currentTypeExchanges.length;
    }
    return 0;
  }, [exchanges, type]);

  const txt = isCustom
    ? DESCRIPTION_CUSTOM_DASHBOARD
    : `Number of monitored entities: <strong>${typeExchangesNumber}</strong>`;

  return (
    <StyledLink href={`${ERoutes.MONITORING}/${type}`} underline="none">
      <StyledTopPartWrapper>
        <Typography key={type} color={'white.100'} textTransform="capitalize" fontSize="18px" fontWeight="bold">
          {getFullExchangeTypeName(type)}
        </Typography>
        <StyledImg src={imagePath} alt={`${type} icon representation`} height="100px" />
      </StyledTopPartWrapper>

      <Typography
        key={type}
        color={'white.80'}
        fontSize="14px"
        align="center"
        dangerouslySetInnerHTML={{ __html: txt }}
      />
    </StyledLink>
  );
};
