import styled from '@emotion/styled';

export const StyledIconWrapper = styled.div`
  padding: 10px;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  --stroke-color: #fff;
  transition: background 0.3s, stroke-color 0.3s;

  &:hover {
    cursor: pointer;
    background: #1b1b1e;
    --stroke-color: #5d51b8;
  }
`;
