import { Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatFloatToPercent } from '@/helpers/helpers';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import {
  StyledLink,
  StyledRowDataWrapper,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { ERoutes } from '@/types/routes';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';
import { isNull } from 'lodash';

export const DefaultRisks = ({
  isRated,
  displayName,
  pdYear,
  pdChange,
}: Pick<IEntityDetailsProps, 'isRated' | 'displayName' | 'pdYear' | 'pdChange'>) => {
  return (
    <>
      <Typography color="white.100" fontSize="14px">
        Default risks
      </Typography>
      {isRated ? (
        <RatedEntity displayName={displayName} pdYear={pdYear} pdChange={pdChange} />
      ) : (
        <Typography color="#ffffff80" fontSize="14px">
          Not rated
        </Typography>
      )}
    </>
  );
};

const RatedEntity = ({
  displayName,
  pdYear,
  pdChange,
}: Pick<IEntityDetailsProps, 'displayName' | 'pdYear' | 'pdChange'>) => {
  const { shouldDisplayRisks } = usePermissionsManager();
  const { getExchangeLabelByDisplayName } = useEntitiesList();

  const blurStyle = { filter: `${shouldDisplayRisks ? 'none' : 'blur(5px)'}` };

  const pdYearToDisplay = !isNull(pdYear) ? formatFloatToPercent(pdYear) : '';
  const pdChangeToDisplay = !isNull(pdChange) ? formatFloatToPercent(pdChange) : '';

  return (
    <>
      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          1 year PD
        </Typography>
        <Typography color="white.100" fontSize="16px" style={blurStyle}>
          {shouldDisplayRisks ? pdYearToDisplay : 'XX.XX%'}
        </Typography>
      </StyledRowDataWrapper>

      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          1W PD change
        </Typography>
        <Typography color="white.100" fontSize="16px" style={blurStyle}>
          {shouldDisplayRisks ? pdChangeToDisplay : 'XX.XX%'}
        </Typography>
      </StyledRowDataWrapper>

      {!!displayName && (
        <StyledRowDataWrapper>
          <StyledLink href={`${ERoutes.RATINGS}/${getExchangeLabelByDisplayName(displayName)}`} underline="none">
            <Typography color="#ffffff80" fontSize="14px" display="flex" alignItems="center" gap="3px">
              Go to details <ArrowForwardIcon fontSize="small" />
            </Typography>
          </StyledLink>
        </StyledRowDataWrapper>
      )}
    </>
  );
};
