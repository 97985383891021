export const CHAINS = [
  'arbitrum_one',
  'bitcoin',
  'avalanche',
  'polygon',
  'bsc',
  'ethereum',
  'optimism',
  'tron',
  'base',
  'solana',
] as const;
