import { createContext, PropsWithChildren } from 'react';
import { useNewFeatureManager } from '@/providers/NewFeatureContextProvider/NewFeatureContext.hooks';
import { INewFeatureContext } from '@/providers/NewFeatureContextProvider/NewFeatureContext.types';

export const NewFeatureContext = createContext<Partial<INewFeatureContext>>({});

export const NewFeatureProvider = ({ children }: PropsWithChildren) => {
  const { isNewFeaturePermitted } = useNewFeatureManager();

  const VALUE: INewFeatureContext = {
    isNewFeaturePermitted,
  };

  return <NewFeatureContext.Provider value={VALUE}>{children}</NewFeatureContext.Provider>;
};
