import { Typography } from '@mui/material';
import { mapChainData } from '@/helpers/mapChartsData';
import { useGetAlertRelatedData } from '@/api/adminAlerts/adminAlerts.hooks';
import WrapperCharts from '@/components/molecules/Charts/WrapperCharts';
import { StyledChartsWrapper } from './StyledComponents';
import { ChartPoint } from '@/types/chartPoint';

interface CreateUpdateModalChartsProps {
  alertId: string;
  anomalyChainData: ChartPoint;
}

const CreateUpdateModalCharts = ({ alertId, anomalyChainData }: CreateUpdateModalChartsProps) => {
  const { fetchedData, isLoading, error } = useGetAlertRelatedData({ alertId: alertId });

  if (isLoading) {
    return <Typography color="white.100">Loading...</Typography>;
  }

  if (!fetchedData || error) {
    return (
      <Typography color="white.100">{error?.message || 'There is no related data to the requested alert.'}</Typography>
    );
  }

  const formattedChainData = fetchedData.chainData ? mapChainData(fetchedData.chainData) : [];
  const formattedNetFlowData = mapChainData(fetchedData.netflowData);
  const formattedTotalFlowData = mapChainData(fetchedData.totalFlowData);

  return (
    <StyledChartsWrapper>
      <WrapperCharts
        alertId={alertId}
        anomalyChainData={anomalyChainData}
        chartData={formattedChainData}
        netflowData={formattedNetFlowData}
        totalflowData={formattedTotalFlowData}
      />
    </StyledChartsWrapper>
  );
};

export default CreateUpdateModalCharts;
