import { useState } from 'react';
import { mapChainData } from '@/helpers/mapChartsData';
import WrapperCharts from '@/components/molecules/Charts/WrapperCharts';
import { StyledChartsWrapper } from '@/views/EntityMonitoringView/components/ChainDataCharts/ChainDataCharts.styled';
import { IGetUserEntityDetailsResponse } from '@/api/userEntities/userEntities.types';

export const ChainDataCharts = ({
  agioId,
  balances,
  netflowData,
  totalFlowData,
  hourlyNetflowData,
  hourlyTotalFlowData,
}: Pick<
  IGetUserEntityDetailsResponse,
  'agioId' | 'balances' | 'netflowData' | 'totalFlowData' | 'hourlyNetflowData' | 'hourlyTotalFlowData'
>) => {
  const [areChartsRendered, setAreChartsRendered] = useState(true);

  const chartData = mapChainData(balances);
  const formattedNetFlowData = mapChainData(netflowData);
  const formattedTotalFlowData = mapChainData(totalFlowData);

  const formattedHourlyNetflowData = mapChainData(hourlyNetflowData);
  const formattedHourlyTotalflowData = mapChainData(hourlyTotalFlowData);

  if (!areChartsRendered) return null;

  return (
    <StyledChartsWrapper>
      <WrapperCharts
        key={agioId}
        alertId={agioId}
        chartData={chartData}
        netflowData={formattedNetFlowData}
        totalflowData={formattedTotalFlowData}
        hourlyNetflowData={formattedHourlyNetflowData}
        hourlyTotalflowData={formattedHourlyTotalflowData}
        setAreChartsRendered={setAreChartsRendered}
      />
    </StyledChartsWrapper>
  );
};
