import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import ChartSelector from '@/components/molecules/ChartSelector/ChartSelector';
import ActionsButtons from '../ActionsButtons/ActionsButtons';
import { mapChainData } from '@/helpers/mapChartsData';
import { useDeleteAlertUpdate, useEditAlertUpdate, useGetAlertByIdByAdmin } from '@/api/adminAlerts/adminAlerts.hooks';
import { IExtendedAlertUpdateDto, TChartType } from '@/types/alert';
import {
  StyledInput,
  StyledQuill,
  StyledText,
  StyledUpdatesContainer,
  StyledWrapperInput,
} from './AdminPanelUpdateDetails.styled';
import { EAdminPaths, ERoutes } from '@/types/routes';

const initialUpdateState: Omit<IExtendedAlertUpdateDto, 'id'> = {
  title: '',
  details: '',
  timestamp: '',
  showChart: false,
  chartType: null,
  deleted: false,
  chainData: [],
  inflowData: [],
  netflowData: [],
  outflowData: [],
  totalFlowData: [],
};

const initialFormState: Omit<
  IExtendedAlertUpdateDto,
  'chainData' | 'netflowData' | 'inflowData' | 'outflowData' | 'totalFlowData' | 'id'
> = {
  title: '',
  details: '',
  timestamp: '',
  showChart: false,
  chartType: null,
  deleted: false,
};

const AdminPanelUpdateDetails = () => {
  const { id, updateID } = useParams<{ id: string; updateID: string }>();
  const [update, setUpdate] = useState<Omit<IExtendedAlertUpdateDto, 'id'>>(initialUpdateState);
  const [formState, setFormState] = useState(initialFormState);
  const navigate = useNavigate();
  const { fetchedAlert, isLoading, error, isSuccess } = useGetAlertByIdByAdmin({ alertId: id || '' });
  const { editAlertUpdate } = useEditAlertUpdate();
  const { deleteAlertUpdate } = useDeleteAlertUpdate();

  useEffect(() => {
    if (!fetchedAlert) return;

    const alertUpdate = fetchedAlert.updates.find((update) => update.id === updateID);

    if (!alertUpdate) return console.error('There is no found requested alert update.');

    if (alertUpdate) {
      setUpdate(alertUpdate);
      setFormState({
        title: alertUpdate.title,
        details: alertUpdate.details,
        timestamp: alertUpdate.timestamp,
        showChart: alertUpdate.showChart,
        chartType: alertUpdate.chartType,
        deleted: alertUpdate.deleted,
      });
    }
  }, [fetchedAlert, updateID]);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const payload = { ...formState, alertId: id || '', updateId: updateID || '' };

    editAlertUpdate(payload, {
      onSuccess: () => {
        navigate(`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}/${id}`);
      },
      onError: (error) => {
        console.error('Error updating alert:', error);
      },
    });
  };

  const handleDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    deleteAlertUpdate(
      { alertId: id || '', updateId: updateID || '' },
      {
        onSuccess: () => {
          navigate(`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}/${id}`);
        },
        onError: (error) => {
          console.error('Error updating alert:', error);
        },
      },
    );
  };

  const handleCancel = () => {
    setUpdate(initialUpdateState);
    setFormState(initialFormState);
    navigate(`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}/${id}`);
  };

  const handleInputChange = (key: string, value: unknown) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChartSelectionChange = (selection: { showChart: boolean; chartType: TChartType }) => {
    setFormState({ ...update, ...selection });
  };

  if (isLoading) {
    return <Typography color="white.100">Loading alert details...</Typography>;
  }

  if ((!fetchedAlert && isSuccess) || error) {
    return <Typography color="white.100">{error?.message || 'There is no alert details.'}</Typography>;
  }

  const formattedChainData = mapChainData(update.chainData);
  const anomalyChainData = formattedChainData.sort((a, b) => a.x - b.x)[formattedChainData.length - 1];

  return (
    <div>
      {update && !update.deleted && (
        <StyledUpdatesContainer>
          <StyledWrapperInput>
            <StyledText fontSize="20px">Date of update</StyledText>
            <StyledInput
              borderColor="#fff"
              type="date"
              value={formState.timestamp}
              onChange={(e) => handleInputChange('timestamp', e.target.value)}
            />
          </StyledWrapperInput>
          <StyledWrapperInput>
            <StyledText fontSize="20px">Title</StyledText>
            <StyledInput
              borderColor="#fff"
              value={formState.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
            />
          </StyledWrapperInput>
          <StyledWrapperInput width="100%">
            <StyledText fontSize="20px">Details</StyledText>

            <StyledQuill
              theme="snow"
              value={formState.details}
              onChange={(e) => handleInputChange('details', e)}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link'],
                  ['clean'],
                  ['code-block'],
                ],
              }}
            />
          </StyledWrapperInput>

          {update.chainData?.length > 0 && (
            <StyledUpdatesContainer>
              <StyledText fontSize="20px">Select the chart</StyledText>
              <ChartSelector
                alertId={id}
                isDataLoaded={isSuccess}
                anomalyChainData={anomalyChainData}
                layout="row"
                chartData={formattedChainData}
                netFlowData={mapChainData(update.netflowData)}
                onSelectionChange={handleChartSelectionChange}
              />
            </StyledUpdatesContainer>
          )}
          <ActionsButtons handleSave={handleSubmit} handleDelete={handleDelete} handleCancel={handleCancel} />
        </StyledUpdatesContainer>
      )}
    </div>
  );
};

export default AdminPanelUpdateDetails;
