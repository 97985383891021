import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { ERoutes } from '@/types/routes';
import { ELocalStorage } from '@/types/storage';
import { EFileKeys } from '@/views/RiskSimulatorPortfolioView/utils/types';
import { useToastManager } from '@/hooks/useToastManager';

export const useUploadRiskSimulatorCsv = () => {
  const navigete = useNavigate();
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { openToast, handleOpenToast, handleCloseToast, basicToastCloseAction } = useToastManager();

  const fileReader = new FileReader();

  const handleUploadExposureButtonClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      setFile(e.target.files[0]);
    }
  };

  const clearFileInput = useCallback(() => {
    setFile(undefined);
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = '';
    }
  }, []);

  useEffect(() => {
    if (file) {
      fileReader.onload = function (event) {
        if (event.target !== null) {
          const csvOutput = event.target.result;
          if (typeof csvOutput === 'string') {
            const parsedCSV = Papa.parse(csvOutput, { header: true });
            const rows = parsedCSV.data;
            if (checkRowsAreExcelRowsType(rows)) {
              localStorage.setItem(ELocalStorage.PORTFOLIO_CSV_DATA, csvOutput);
              return navigete(ERoutes.RISK_SIMULATOR_PORTFOLIO);
            }

            console.error('Excel Headers are incorrect');
            handleOpenToast();
            clearFileInput();
          }
        }
      };

      fileReader.readAsText(file);
    }
  }, [file]);

  return {
    fileInputRef,
    handleFileInputChange,
    handleUploadExposureButtonClick,
    openToast,
    handleCloseToast,
    basicToastCloseAction,
  };
};

const checkRowsAreExcelRowsType = (rows: unknown[]): boolean => {
  if (!Array.isArray(rows)) return false;

  const areRowsExcelRows = rows.map((row) => {
    if (row && typeof row === 'object') {
      const keys = Object.keys(row);
      const enumValues = Object.values(EFileKeys);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return keys.every((key) => enumValues.includes(key));
    }

    return false;
  });

  if (areRowsExcelRows.length > 0) {
    return areRowsExcelRows.every((type) => type);
  }

  return false;
};
