import { useEffect, useMemo } from 'react';
import DeleteIcon from '@/assets/icons/delete-new.svg?react';
import { emptyRow } from '@/views/RiskSimulatorPortfolioView/utils/constants';
import { formatFloatToPercent } from '@/helpers/helpers';
import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { ControlledBaseTextField, EInputValueFormatter } from '@/components/atoms/BaseTextField/BaseTextField';
import { ExchangeInput } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/ExchangeInput';
import { PdInput } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/PdInput';
import { StyledMuiTextInput } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/Input.styled';
import {
  StyledDeleteButton,
  StyledFirstCell,
  StyledIconWrapper,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/SingleRow.styled';

export interface ISingleRowProps {
  idx: number;
  isCustom: boolean;
}

export const SingleRow = ({ idx, isCustom }: ISingleRowProps) => {
  const {
    riskSimulatorMethods,
    watchedRowsValues,
    autocompleteManager,
    ratings,
    rowsManager,
    totalExposure,
    datePickerManager,
  } = useRiskSimulator();

  const { setValue } = riskSimulatorMethods;
  const { formattedSelectedDate } = datePickerManager;
  const { removeRow } = rowsManager;
  const { isMultipleEntities, filteredOptions, handleSelectedOptionChange, removeDeletedOptionFromSelections } =
    autocompleteManager;

  const formattedPercentageExposure = useMemo(() => {
    if (watchedRowsValues[idx].exposure) {
      const value = (watchedRowsValues[idx].exposure / totalExposure) * 100;

      return isNaN(value) ? '--.--%' : formatFloatToPercent(value);
    }

    return '--.--%';
  }, [watchedRowsValues[idx].exposure, setValue, idx, totalExposure]);

  const handleRemoveClick = () => {
    removeRow(idx);
    removeDeletedOptionFromSelections(idx);
  };

  useEffect(() => {
    if (watchedRowsValues[idx].exposure) {
      const value = (watchedRowsValues[idx].exposure / totalExposure) * 100;
      const calculatedFormValue = isNaN(value) ? 0 : value;

      setValue(`rows.${idx}.percentExposure`, calculatedFormValue);
      return;
    }

    setValue(`rows.${idx}.percentExposure`, 0);
  }, [idx, setValue, totalExposure, watchedRowsValues[idx].exposure]);

  useEffect(() => {
    if (watchedRowsValues[idx].entity) {
      const rating = ratings.find((r) => r.entity === watchedRowsValues[idx].entity);
      if (rating) {
        const findPD = rating.data.find((d) => formattedSelectedDate === d.timestamp.split('T')[0]);
        return setValue(`rows.${idx}.pd`, findPD?.value || emptyRow.pd);
      }
    }

    if (!watchedRowsValues[idx].entity && !watchedRowsValues[idx].isCustom) {
      setValue(`rows.${idx}.pd`, emptyRow.pd);
    }
  }, [watchedRowsValues[idx].entity, ratings, setValue, formattedSelectedDate]);

  return (
    <>
      <StyledFirstCell>
        <ExchangeInput
          formMethods={riskSimulatorMethods}
          isCustom={isCustom}
          idx={idx}
          selectOptions={filteredOptions}
          onChange={handleSelectedOptionChange}
        />
      </StyledFirstCell>
      <div>
        <ControlledBaseTextField
          valueFormatter={EInputValueFormatter.DOLLAR}
          formMethods={riskSimulatorMethods}
          inputName={`rows.${idx}.exposure`}
          placeholder="Enter Amount ($)"
          helperTextBackgroundColor="#26284b"
          CustomTextInput={StyledMuiTextInput}
        />
      </div>
      <div>
        <ControlledBaseTextField
          valueFormatter={EInputValueFormatter.PERCENTAGE}
          formMethods={riskSimulatorMethods}
          inputName={`rows.${idx}.recovery`}
          helperTextBackgroundColor="#26284b"
          CustomTextInput={StyledMuiTextInput}
        />
      </div>
      <div>
        <PdInput formMethods={riskSimulatorMethods} idx={idx} isCustom={isCustom} formRowsValues={watchedRowsValues} />
      </div>

      <div>
        <StyledMuiTextInput disabled value={formattedPercentageExposure} />
      </div>
      {isMultipleEntities && (
        <StyledIconWrapper>
          <StyledDeleteButton disableRipple skipTypography blockHoverBehavior onClick={handleRemoveClick}>
            <DeleteIcon />
          </StyledDeleteButton>
        </StyledIconWrapper>
      )}
    </>
  );
};
