import { RiskMeasures } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/RiskMeasures/RiskMeasures';
import { SimulatedCharts } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulatedCharts/SimulatedCharts';
import { StyledCalculationsContainer } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/Calculations/Calculations.styled';

export const Calculations = () => {
  return (
    <StyledCalculationsContainer>
      <RiskMeasures />
      <SimulatedCharts />
    </StyledCalculationsContainer>
  );
};
